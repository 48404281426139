import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import FormFieldError from '../../../utils/error-message';
import common from '../../../utils/common';
import FormValidationIcon from '../../../utils/form-validation-icon';
import { Cancel } from '../common/icons/cancel';

export const TeamPopup = (props) => {
    const { register, formState: { errors, touchedFields, dirtyFields }, setValue, getValues } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all"
    });
    
    useEffect(() => {
        if(props.value && props.value.data) {
            Object.entries(props.value && props.value.data).forEach(val => {
                if(val[1] && (val[1] !== "")) {
                    setValue(val[0],val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true});
                }
            })
        }
    },[])
    
    /** ADD TEAM MEMBER */
    function addMember() {
        const data = getValues();
        props.save(data,  props.value && (props.value.index !== null && props.value.index !== "" && props.value.index !== undefined)? props.value.index : null);
        props.close()
    }
    
  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 overlay-background bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full  justify-center p-4  sm:items-center sm:p-0">
                <div className="team_popup relative w-[360px] m-auto transform overflow-hidden rounded-md bg-white p-[10px] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] dark:bg-[#1D1D1D] dark:shadow-[0px_4px_21px_rgba(138, 138, 138, 0.2)]">
                    <div className="sm:items-start w-full flex">
                        <div className="p-[24px] sm:text-left w-full">
                            <div className="absolute right-[21px] xs:mt-[-10px] pr-3 sm:block mt-[10px]">
                                <button type="button" onClick={props.close} className="rounded-md !bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]">
                                    <Cancel/>
                                </button>
                            </div>
                            <h2 className='text-[20px] font-bold'>Add team member</h2>
                            <hr className='mt-[10px]'/>
                            <div className="field-group w-[100%] mt-[35px]">
                                <input type="text" {...register("name")} name="name" placeholder=" " className="input-fld" 
                                {...register("name", {
                                    maxLength: {
                                    value: 30,
                                    message: common.formValidation('Full Name', 'maxLength', "", 30)
                                    }
                                })}
                                />
                                <label htmlFor="name" className="input-lbl">Full name</label>
                                <FormValidationIcon getval={getValues("name")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"name"}></FormValidationIcon>
                            </div>
                            <FormFieldError errors={errors} fieldname={"name"}></FormFieldError>
                            <div className="field-group w-[100%] mt-[35px]">
                                <input {...register("email")} type="text" name="email" placeholder=" " className="input-fld"
                                {...register("email", {
                                    pattern: {
                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                    message: common.formValidation('Email', 'pattern')
                                    },
                                    minLength: {
                                    value: 5,
                                    message: common.formValidation('Email', 'minLength', 5)
                                    },
                                    maxLength: {
                                    value: 120,
                                    message: common.formValidation('Email', 'maxLength', "", 120)
                                    }
                                })}
                                />
                                <label htmlFor="email" className="input-lbl">Email</label>
                                <FormValidationIcon getval={getValues("email")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"email"}></FormValidationIcon>
                            </div>
                            <FormFieldError errors={errors} fieldname={"email"}></FormFieldError>
                            <div className='flex justify-between mt-[35px] [@media(max-width:640px)]:flex-col-reverse [@media(max-width:640px)]:gap-[10px]'>
                                <button className='w-[65px] h-[34px] rounded-border-button [@media(max-width:640px)]:w-full' onClick={props.close}>Back</button>
                                <button className=' w-[105px] h-[34px] rounded-fill-button [@media(max-width:640px)]:w-full dark:hover:!bg-[#4C4C4C]' onClick={addMember}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
