import { useEffect, useRef, useState } from "react"
import FormValidationIcon from "../../../utils/form-validation-icon"
import { ClientOnboardingTemplate } from "../common/client-onboarding-template"
import { FooterWithBackNext } from "../common/footer-with-back-next"
import { Header } from "../common/header"
import { Website } from "../common/icons/website"
import { TextContainer } from "../common/text-container"
import { useForm } from "react-hook-form"
import { SelectFormFieldError } from "../../../utils/error-message"
import common from "../../../utils/common"
import FormSearchIcon from "../../../utils/form-search-icon"
import { Plus } from "../common/icons/plus"
import { useDispatch, useSelector } from "react-redux"
import { GetCompanies } from "../../service/commonapi"

export const BrandsAndCreative = (props) => {
    const head = "Great! Now let’s set the <b class='text-[48px] font-bold'>highest bar</b>.";
    const subHead = "Tell us about the brands you wish you’d build. The kind work you want to beat (even if it’s your own).";
    const clientStore = useSelector(state => state.clientStore);
    const selectStore = useSelector(state => state.selectStore);
    const [brands, setBrands] = useState([]);
    const [brandsDrop, setBrandsDrop] = useState([]);
    const [brandsTempDrop, setBrandsTempDrop] = useState([]);
    const brandsRefDrop = useRef();
    const fullnameRef = useRef();
    const agencynameRef = useRef();
    const linkedinurlRef = useRef();
    const agencylinkedinurlRef = useRef();
    const brandsRef = useRef();
    const dispatch = useDispatch();
    const { register, formState: { errors, touchedFields, dirtyFields }, getValues, setValue } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all"
    });

    useEffect(() => {
        getCompany();
    },[clientStore.page6])

    useEffect(() => {
        window.addEventListener("scroll", resetDropdownPosition);
        return () => {
          window.removeEventListener("scroll", resetDropdownPosition);
      };
    })

    useEffect(() => {
        var parent = brandsRef.current;
        if(parent) {
            parent = parent.getBoundingClientRect()
            document.querySelector('.brands.demoFixed').style.top = parent.top - 9 + 'px';
        }
    }, [brands])
    
      /**Recalculate Position on scroll*/
    function resetDropdownPosition() {
        var brandParent = brandsRef.current;
        if(brandParent) {
          brandParent = brandParent.getBoundingClientRect();
            if(document.querySelector('.brands.demoFixed')) {
                document.querySelector('.brands.demoFixed').style.top = brandParent.top - 9 + 'px';
                document.querySelector('.brands.demoFixed').style.left = brandParent.left + 'px';
                document.querySelector('.brands.demoFixed').style.width = brandParent.width + 'px';
            }
        }
    }

    function getCompany() {
        try {
            GetCompanies("").then((res) => {
              if (res) {
                var modifiedObj = res.map(obj => ({ ...obj, isChecked: false }));
                if (clientStore.page6.brands && Array.isArray(clientStore.page6.brands) && clientStore.page6.brands.length) {
                  var _brands = []
                  var branValues = JSON.parse(JSON.stringify(clientStore.page6.brands))
                  branValues.forEach((_val, i) => {
                    var index = modifiedObj.findIndex(x => x.company_id === _val.company_id);
                    _brands.push(_val.company_id);
                    if (index !== -1) {
                      modifiedObj[index]["isChecked"] = true;
                    }
                  })
                  if (_brands.length >= 1) {
                    setValue('brands', _brands, { shouldTouch: true, shouldValidate: true, shouldDirty: true, });
                    setBrands(branValues);
                  }
                } else {
                  setValue('brands', _brands, { shouldTouch: true, shouldValidate: true, shouldDirty: true,});
                }
                setBrandsDrop(modifiedObj);
                setBrandsTempDrop(modifiedObj)
              } else {
                setBrandsDrop([]);
                setBrandsTempDrop([])
              }
            })
        } catch (err) { console.log(err); }
    }

    const enableBrandsFirstLevel = () => {
        if (document.querySelectorAll('.brands .demoFixedSubLevel')) {
          [...document.querySelectorAll('.brands .demoFixedSubLevel')].forEach(div => {
            if (div.style.display !== 'none') {
              div.style.display = 'none'
            }
          });
        }
        // setBrandsLabel(false)
        const mark = document.querySelector(".brands-label");
        if (mark) {
          mark.classList.remove("move-label");
        }
        const parent = brandsRef.current.getBoundingClientRect();
        document.querySelector('.brands.demoFixed').style.left = parent.left + 'px';
        document.querySelector('.brands.demoFixed').style.top = parent.top - 9 + 'px';
        document.querySelector('.brands.demoFixed').style.width = parent.width + 'px';
        document.querySelector('.react-select__menu.brands.demoFixed').style.display = 'block';
        document.querySelector('.brands-multi-select-backdrop').style.display = 'block';
    }
    const disableAllBrandsLevel = () => {
    if (document.querySelector('.brands.demoFixed').style) {
        document.querySelector('.brands.demoFixed').style.display = 'none';
        if (brands && !brands.length >= 3) {
        selectStore.brands.forEach((_val, _i) => {
            document.querySelector('.react-select__menu.brands .demoFixedSubLevel.child_' + _i).style.display = 'none'
        })
        }
        document.querySelector('.brands-multi-select-backdrop').style.display = 'none';
    }
    }
    function brandsOnchange(val, i, e) {
        if(e) {
            e.preventDefault()
            e.stopPropagation();
        }
        var _val = JSON.parse(JSON.stringify(brandsDrop));
        var _brands = JSON.parse(JSON.stringify(brands));
        var _valueIndex = _brands.findIndex(value => value.company_id === val.company_id);
        if (_val[i]["isChecked"]) {
            _val[i]["isChecked"] = false;
            if (_valueIndex !== -1) {
            _brands.splice(_valueIndex, 1);
            setBrands(_brands);
            }
        } else {
            _val.forEach((val, j) => {
            if (i === j) {
                _val[j]["isChecked"] = true;
                _brands.push(_val[j])
                setBrands(_brands);
            }
            })
        }
        setBrandsDrop(_val);
        var _data = JSON.parse(JSON.stringify(clientStore.page6));
        let data = [];
        _brands.forEach((_val) => {
            data.push(_val.company_id);
        });
        _data["brands"] = _brands;
        dispatch({ type: "client_details/insertValue", payload: { id: _data, name: "page6" }, });
        //props.saveData({ brands: data });
    }  
    function onInputChange(event) {
        const _searchText = event.target.value;
        if (_searchText !== "" && _searchText.length >= 3) {
          GetCompanies(_searchText).then((res) => {
            if (res) {
              var updatedVal = []
              res.forEach((v) => {
                var _val = {};
                _val["label"] = v.label;
                _val["company_id"] = v.company_id;
                _val["isChecked"] = false;
                _val["logo"] = v.logo
                updatedVal.push(_val)
              })
              var brns = JSON.parse(JSON.stringify(brands));
              brns.forEach((val, i) => {
                var ind = updatedVal.findIndex(v => v.company_id === val.company_id)
                if (ind !== -1) {
                  updatedVal[ind]["isChecked"] = true
                }
              })
              setBrandsDrop(updatedVal)
              // setOptions(options);
              return updatedVal;
            } else {
              // setIsLoading(false)
            }
          })
        } else if (!_searchText || _searchText.length === 0 || _searchText === "") {
          setBrandsDrop(brandsTempDrop)
        }
    }
    function removeHandler(val, i) {
        const brandValue = [...brands];
        brandValue.splice(i, 1);
        setBrands(brandValue);
        var _data = JSON.parse(JSON.stringify(clientStore.page6));
        let data = [];
        brandValue.forEach((_val) => {
          data.push(_val.company_id);
        });
        _data["brands"] = brandValue;
        dispatch({ type: "client_details/insertValue", payload: { id: _data, name: "page6" }, });
        // props.saveData({ "brands": data });
    }  
    function brandsBlurHandler() {
    // setBrandsLabel(false)
    const mark = document.querySelector(".brands-label");
    if (mark) {
        mark.classList.add("move-label");
    }
    if (document.getElementsByName('brands')) {
        document.getElementsByName('brands').forEach(o => {
        if (o && o.value !== "") {
            o.value = ""
        }
        })
    }
    }
    function textOnchangeSave(name) {
        try {
            var _data = JSON.parse(JSON.stringify(clientStore.page6));
            if ( getValues(name) !== "" && getValues(name) !== _data[name] &&  touchedFields[name] &&  !errors[name] ) {
                _data[name] = getValues(name);
                dispatch({  type: "client_details/insertValue", payload: { id: _data, name: "page6" } });
                var data = {};
                data[name] = getValues(name);
                //props.saveData(data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    function submitHandler(){
        props.nextStep();
    }
    
    return(
        <div className="page-section">
            <Header isClient={true}></Header>
            <ClientOnboardingTemplate>
                <div className="role-textContainer">
                    <TextContainer head={head} subHead={subHead} activeTab="Brand that inspire you" activepersentage="71.4%" text="6/7"/>
                </div>
                <form className="form-layer brands space-y-11 " autoComplete="off">
                    <div className="field-group custom-select brands-placeholder">
                        <label className={"absolute move-label brands-label diversity-edge text-[14px] text-[#a3a3a3] -z-1 origin-0 select-label"}>Brands that inspires you</label>
                        <input type="text" ref={brandsRef} name="brands" onFocus={enableBrandsFirstLevel} onInputCapture={onInputChange} onBlur={brandsBlurHandler} className="input-fld" />
                        {brands && brands.length < 1 && <FormSearchIcon />}
                        <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={brands}></FormValidationIcon>
                        <div className={'brands-multi-select-backdrop multi-select-backdrop '} onClick={disableAllBrandsLevel}></div>
                    </div>
                    <div ref={brandsRefDrop} className="multi-select react-select__menu brands demoFixed">
                        <div className="react-select__menu-list react-select__menu-list--is-multi single-level">
                        {JSON.parse(JSON.stringify(brandsDrop)).length <= 0 && <div className="react-select__menu-notice react-select__menu-notice--no-options css-1wlit7h-NoOptionsMessage">Enter company name</div>}
                        {JSON.parse(JSON.stringify(brandsDrop)).map((diver, i) =>
                            <div className={"react-select__option"} key={i}>
                                <li className={"cursor-pointer"} onClick={(e) => { brandsOnchange(diver, i, e) }}>
                                    <span className="txt" >
                                        <div className='flex items-center'>
                                            {/* <div className="flag-div mr-[10px]">
                                            <img className="flagSVG h-[20px] w-[20px]" src={diver.logo} alt="brand" />
                                            </div> */}
                                            <span>{diver.label}</span>
                                        </div>
                                        {/* <label className="ch-box">
                                        <input type="checkbox" onChange={() => { brandsOnchange(diver, i) }} readOnly checked={diver.isChecked} className=" text-blue-600 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                                        </label> */}
                                        <label className="control control--checkbox">
                                            <input type="checkbox" onChange={() => { brandsOnchange(diver, i) }} readOnly checked={diver.isChecked}/>
                                            <div className="control__indicator"></div>
                                        </label>
                                    </span>
                                </li>
                            </div>
                        )}
                        </div>
                    </div>
                    <SelectFormFieldError isValid={true}></SelectFormFieldError>
                    <div className={`${brands && brands.length ? '!mt-0 !mb-[24px] pb-[30px] custom-multi-select' : ''}  `}>
                        {brands && brands.map((val, i) => {
                        return (
                            <div key={i} className="react-select__multi-value new">
                            <div className="react-select__multi-value__label">
                                <div className="brands-option flex m-auto">
                                {/* <div className="flag-div mr-[10px]">
                                    <img
                                    className="flagSVG h-[20px] w-[20px]"
                                    src={val.logo}
                                    alt="brand"
                                    />
                                </div> */}
                                <span className="mar-label">{val.label}</span>
                                </div>
                            </div>
                            <div
                                role="button"
                                onClick={() => removeHandler(val, i)}
                                className="react-select__multi-value__remove"
                                aria-label="Remove X"
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                                </svg>
                            </div>
                            </div>
                        );
                        })}
                    </div>
                      
                    <p className='text-[18px] pt-[18px] text-[#4C4C4C] dark:text-[#FFFFFF] font-normal leading-[130%] tracking-[-0.03rem]'>Creative Agencies that you like</p> 
                    <div className="field-group w-full mt-[30px]">
                        <input ref={agencynameRef} type="text" name="name" placeholder=" " className="input-fld" 
                        onBlurCapture={() => { textOnchangeSave("name") }}  
                        {...register("name", {
                            required: common.formValidation('Agency Name', 'required'),
                            maxLength: {
                                value: 30,
                                message: common.formValidation('Agency Name', 'maxLength', "", 30)
                            }
                        })}
                        />
                        <FormValidationIcon getval={getValues("name")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"name"}></FormValidationIcon>
                        <label htmlFor="name" className="input-lbl">Agency Name</label>
                    </div>
                    {/* <FormFieldError errors={errors} fieldname={"name"}></FormFieldError> */}
                    <div className="field-group w-full mt-[40px]">
                        <input ref={agencylinkedinurlRef} type="text" name="linkedinurl" placeholder=" " className="input-fld" 
                        onBlurCapture={() => { textOnchangeSave("linkedin_url") }}
                            {...register("linkedin_url", {
                                required: common.formValidation('Linkedin URL', 'required'),
                                pattern: {
                                    value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                                    message: common.formValidation('Linkedin URL', 'pattern')
                                },
                                minLength: {
                                    value: 5,
                                    message: common.formValidation('Linkedin URL', 'minLength', 5)
                                }
                            })}
                        />
                        <FormValidationIcon getval={getValues("linkedin_url")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"linkedin_url"}></FormValidationIcon>
                        <label htmlFor="linkedinurl" className="input-lbl">Agency Linkedin URL</label>
                        <span className="absolute right-[4px] bottom-[8px]"><Website /></span>
                    </div>
                    {/* <FormFieldError errors={errors} fieldname={"linkedin_url"}></FormFieldError> */}
                    <button className="py-[8px] px-[16px] mt-[20px] w-[124px] border-[1px] border-solid dark:border-[#FFFFFF] border-[#1D1D1D] dark:text-[#FFFFFF] text-[#1D1D1D] h-[36px] text-[14px] leading-[14px] rounded-3xl font-bold helvetica flex justify-center items-center gap-[4px]">Add more
                        <i className="inline-block ml-[3px]"><Plus/></i>
                    </button>
                    <p className='text-[18px] mt-[40px] text-[#4C4C4C] dark:text-[#FFFFFF] font-normal leading-[130%] tracking-[-0.03rem]'>People whose work inspires you</p> 
                    <div className="field-group w-full mt-[30px]">
                        <input ref={fullnameRef} type="text" name="fullname" placeholder=" " className="input-fld"
                            onBlurCapture={() => { textOnchangeSave("full_name"); }}
                            {...register("full_name", {
                            required: common.formValidation("Full Name", "required"),
                            maxLength: { value: 30,
                                message: common.formValidation( "Full Name", "maxLength", "", 30 ),
                            },
                            })}
                        />
                        <FormValidationIcon getval={getValues("full_name")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"full_name"} ></FormValidationIcon>
                        <label htmlFor="fullname" className="input-lbl" >Full Name</label>
                    </div>
                    <div className="field-group w-full mt-[40px]">
                        <input ref={linkedinurlRef} type="text" name="linkedinurl" placeholder=" " className="input-fld"
                        onBlurCapture={() => { textOnchangeSave("linkedin_profile_url");  }}
                        {...register("linkedin_profile_url", {
                            required: common.formValidation("Person's Linkedin URL", "required"),
                            pattern: {
                            value:
                                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                            message: common.formValidation("Linkedin URL", "pattern"),
                            },
                            minLength: {
                            value: 5,
                            message: common.formValidation( "Linkedin URL", "minLength", 5 ),
                            },
                        })}
                        />
                        <FormValidationIcon getval={getValues("linkedin_profile_url")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"linkedin_profile_url"} ></FormValidationIcon>
                        <label htmlFor="linkedinurl" className="input-lbl" >Person's Linkedin URL</label>
                        <span className="absolute right-[4px] bottom-[8px]"><Website /></span>
                    </div>
                    {/* <FormFieldError errors={errors} fieldname={"linkedin_profile_url"} ></FormFieldError>  */}
                    <button className="py-[8px] px-[16px] mt-[20px] w-[124px] border-[1px] border-solid dark:border-[#FFFFFF] border-[#1D1D1D] dark:text-[#FFFFFF] text-[#1D1D1D] h-[36px] text-[14px] leading-[14px] rounded-3xl font-bold helvetica flex justify-center items-center gap-[4px]">Add more
                        <i className="inline-block ml-[3px]"><Plus/></i>
                    </button>
                </form>
            </ClientOnboardingTemplate>
            <FooterWithBackNext showSkip={true} skipStep={props.skipStep} prevStep={props.prevStep} submitHandler={submitHandler} persentage="57.1%" text="5/7"></FooterWithBackNext>
        </div>
    )
}