import { createSlice } from '@reduxjs/toolkit'

export const agency = createSlice({
    name: 'agency_profile',
    initialState: {
        tab1: {
            profile_avatar_url: "", cover_photo_url: "",
            name: "", location: "",
            title: "",
            heading: "",
            rating: "",
            leaders: [],
            skills: [],
            services: [],
            awards: [],
            diversity: [],
            industries: []
        },
        tab2: {
            project: []
        },
        tab3: {
            awards: []
        },
        tab4: {
            industries: []
        },
        tab5: {
            work: []
        },
        tab6: {
            network: []
        },
        tab7: {
            awardList: []
        }
    },
    reducers: {
        insertValue: (state, action) => {
            state[action.payload.name] = action.payload.id;
            return state
        }
    },
})

// Action creators are generated for each case reducer function
export const { insertValue } = agency.actions

export default agency.reducer