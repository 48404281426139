import { Controller, useForm } from "react-hook-form"
import FormValidationIcon from "../../../utils/form-validation-icon"
import { ClientOnboardingTemplate } from "../common/client-onboarding-template"
import { FooterWithBackNext } from "../common/footer-with-back-next"
import { Header } from "../common/header"
import { TextContainer } from "../common/text-container"
import Select from "react-select"
import { Dropdown } from "../common/icons/dropdown"
import { Plus } from "../common/icons/plus"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Edit } from "../common/icons/edit"
import { Cancel } from "../common/icons/cancel"
import common from "../../../utils/common"
import rendertoast from "../../../utils/rendertoast"
import { ExperiencePopup } from "./experience-popup"

export const Experience = (props) => {
    const head = "What got you this <b class='text-[48px] font-bold'>far</b>?";
    const subHead = "Drop 1-3 workplaces. You know, the places that defined you and your craft.";
    const clientStore = useSelector(state => state.clientStore);
    const selectStore = useSelector(state => state.selectStore);
    const [showPopup, setShowPopup] = useState(false)
    const [experience, setExperience] = useState([]);
    const [experienceEdit, setOpenExperienceEdit] = useState()
    const [yearLabel, setyearLabel] = useState(false);
    const [year, setyear] = useState("");
    const [yearsOfExperience, setYearOfExperience] = useState([]);
    const dispatch = useDispatch();
    const { formState: { errors, touchedFields, dirtyFields }, setValue, getValues, control } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all"
    });

    useEffect(() => {
        var listOfYears = Array.from({ length: 50 }, (_, i) => i + 1);
        listOfYears = listOfYears.map((val) => ({ value: val, label: val.toString() }));
        setYearOfExperience(listOfYears);
        Object.entries(clientStore.page4).forEach(val => {
         if(val[0] === "exeperience"){
            if (val[1] && (val[1] !== "" || (Array.isArray(val[1]) && val[1].length))) {
              setExperience(val[1]);
            }
         }
         if(val[0] === "year_of_experience"){
          Object.entries(listOfYears).forEach(_val => {
            if (_val[1].value === clientStore.page4.year_of_experience) {
              setyearLabel(true);
              setyear(_val[1]);
              setValue(val[0], _val[1].value, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
            }
          })
         } 
        })
    }, [clientStore.page4]);
    
      /**Process values submitted from popup */
    function saveValues(values, index) {
        var data = [...experience];
        try {
            if (values && (index || index === 0) && data.length) {
                data[index] = values;
                setExperience(data)
                onChangeData(data)
            } else {
                data = [...data, values];
                setExperience(data)
                onChangeData(data)
            }
        } catch (err) { console.log(err); }
    }
    
      /** HANDLES ONCHANGE DATA IN EDIT */
    function onChangeData(_experience) {
    var experienceList = [];
    var experienceListForStore = [];
    _experience.forEach((data) => {
        let modifiedDataforStore = {
        "ended_at": (data['ended_at'] || data['end_at']) ? (data['ended_at'] || data['end_at']) : "",
        "roles_id": data['roles_id'] ? data['roles_id'] : "",
        "company_id": data['company_id'] ? data['company_id'] : 0,
        "started_at": (data['started_at'] || data['start_at']) ? (data['started_at'] || data['start_at']) : "",
        "company": data['company'] ? data['company'] : "",
        "is_current" : data['is_current']
        }
        let modifiedData = {
        "ended_at": (data['ended_at'] || data['end_at']) ? (data['ended_at'] || data['end_at']) : "",
        "roles_id": data['roles_id'] ? data['roles_id'] : "",
        "company_id": data['company_id'] ? data['company_id'] : 0,
        "started_at": (data['started_at'] || data['start_at']) ? (data['started_at'] || data['start_at']) : "",
        "is_current" : data['is_current']
        }
        experienceList.push(modifiedData);
        experienceListForStore.push(modifiedDataforStore)
    })
    dispatch({ type: 'client_details/insertValue', payload: { "id": { exeperience: experienceListForStore }, "name": "page4" } });
    // props.saveData({ experience: experienceList });
    }
    
      /**Submit experience list on next click*/
    function submitHandler() {
    var experienceList = [];
    var experienceListForStore = [];
    try {
        experience.forEach((data) => {
        let modifiedDataforStore = {
            "ended_at": (data['ended_at'] || data['end_at']) ? (data['ended_at'] || data['end_at']) : common.dateConverter(new Date().toString()),
            "roles_id": data['roles_id'] ? data['roles_id'] : "",
            "company_id": data['company_id'] ? data['company_id'] : 0,
            "started_at": (data['started_at'] || data['start_at']) ? (data['started_at'] || data['start_at']) : "",
            "company": data['company'] ? data['company'] : "",
            "is_current" : data['is_current']
        }
        let modifiedData = {
            "ended_at": (data['ended_at'] || data['end_at']) ? (data['ended_at'] || data['end_at']) : common.dateConverter(new Date().toString()),
            "roles_id": data['roles_id'] ? data['roles_id'] : "",
            "company_id": data['company_id'] ? data['company_id'] : 0,
            "started_at": (data['started_at'] || data['start_at']) ? (data['started_at'] || data['start_at']) : "",
            "is_current" : data['is_current']
        }
        experienceList.push(modifiedData);
        experienceListForStore.push(modifiedDataforStore)
        })
        var _year_of_experience =  getValues('year_of_experience') ? getValues('year_of_experience'):0;
        dispatch({ type: 'client_details/insertValue', payload: { "id": { exeperience: experienceListForStore ,year_of_experience:_year_of_experience }, "name": "page4" } });
        props.nextStep();
    } catch (err) { console.log(err); }
    }
    
      /**Edit Experience details in list*/
    function editValue(data, index) {
    setShowPopup(true)
    document.body.classList.add("overflow-y-hidden")
    setOpenExperienceEdit({ data: data, index: index })
    }
    
    function closePopup(){
    document.body.classList.remove("overflow-y-hidden")
    setShowPopup(false)
    }

    function handleYearSelectOnchange(e) {
        try {
            var _data = JSON.parse(JSON.stringify(clientStore.page4));
            if (e) {
                setyear(e);
                setyearLabel(true)
                setValue("year_of_experience", e.value, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                _data["year_of_experience"] = e.value;
                dispatch({ type: "client_details/insertValue", payload: { "id": _data, "name": "page4" } });
                // props.saveData({ "year_of_experience": e.value })
            } else {
                setyear("");
                setyearLabel(false)
                setValue("year_of_experience", '', { shouldTouch: false, shouldValidate: false, shouldDirty: false });
                _data["year_of_experience"] = '';
                dispatch({ type: "client_details/insertValue", payload: { "id": _data, "name": "page4" } });
                // props.saveData({ "year_of_experience": 0 })
            }
        } catch (err) { console.log(err); }
    }

    /**Delete Experience from list */
    function deleteValue(index) {
        const data = [...experience];
        data.splice(index, 1);
        setExperience(data);
        setOpenExperienceEdit();
        onChangeData(data)
    }

    function getLookup(value, field) {
        let storeData;
        try {
        if (value[field] && value[field] !== "") {
            if (field === 'roles_id') {
            storeData = selectStore.role;
            } else if (field === 'company_id') {
            storeData = value['company'] ? [value['company']] : null;
            }
            if (storeData && storeData.length) {
            let indexField = field === 'roles_id' ? 'role_id' : field;
            var index = storeData.findIndex(x => x[indexField] === value[field]);
            return storeData[index]['sublabel'] ? storeData[index]['label'] + ' - ' + storeData[index]['sublabel'] : storeData[index]['label']
            } else {
            return "";
            }
        } else {
            return "";
        }
        } catch (err) { console.log(err); }
    }

     /**Open Add Awards Popup */
    function addExperience() {
        try {
        if (experience && experience.length < 3) {
            setShowPopup(true);
            document.body.classList.add("overflow-y-hidden")
            setOpenExperienceEdit()
        } else {
            rendertoast.showToast({ "type": "s", "msg": "Cannot add more than 3 experience" })
        }
        } catch (err) { console.log(err); }
    }

    /**GET Full Formatted DATE */
    function getFullDate(date, start) {
        let presentDate = new Date();
        if ((date.toLocaleString('en-us', { month: 'short', year: 'numeric' }) === presentDate.toLocaleString('en-us', { month: 'short', year: 'numeric' }))) {
        if(start === 'start') {
            return date.toLocaleString('en-us', { month: 'short', year: 'numeric' })
        } else {
            return 'Present'
        }
        } else {
        return date.toLocaleString('en-us', { month: 'short', year: 'numeric' })
        }
    }

    return(
        <div className="page-section">
            <Header isClient={true}></Header>
            <ClientOnboardingTemplate>
                <div className="role-textContainer">
                    <TextContainer head={head} subHead={subHead} activeTab="Experience" activepersentage="42.8%" text="4/7"/>
                </div>
                <div className='experience-container'>
                    <form className="form-layer experience space-y-11 " autoComplete="off">
                        <div className="field-group">
                            <label className={(yearLabel || getValues('year_of_experience')) ? "input-lbl select-label" : "hidden"}>Years of experience</label>
                            <Controller
                            name="year_of_experience"
                            control={control}
                            render={({ field }) => (
                                <Select
                                menuPortalTarget={document.body} menuPosition={'fixed'}
                                isClearable
                                value={year}
                                onChange={(e) => { handleYearSelectOnchange(e) }}
                                onBlurName="onChange"
                                ref={field.ref}
                                onFocus={() => (setyearLabel(true))}
                                onBlur={() => { setyearLabel(year ? true : false) }}
                                classNamePrefix={"react-select"}
                                placeholder={<label className={(!yearLabel && !getValues("year_of_experience")) ? "input-lbl top-[8px]" : 'hidden'}>Years of experience</label>}
                                options={yearsOfExperience}
                                formatOptionLabel={(loc) => (
                                    <div className="location-option single-select-default flex">
                                    <span className="loc-label">{loc.label}</span>
                                    </div>
                                )}
                                />
                            )}
                            />
                            <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>
                            <FormValidationIcon getval={getValues("year_of_experience")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"year_of_experience"}></FormValidationIcon>
                        </div>
                        <div className="field-group !mt-[50px]">
                            <input type="text" name="fullname" placeholder=" " className="input-fld !mb-0" disabled />
                            <label htmlFor="fullname" className="absolute text-[14px] top-[-4px] text-[#a3a3a3] -z-1 duration-300 origin-0">Experience</label>
                        </div>
                        <button onClick={() => addExperience()} type="button" className="hover:!bg-[#4C4C4C] dark:hover:!bg-[#F5F5F5]  mt-[16px] text-[14px] h-[36px] inline-flex bg-c-black dark:!bg-white dark:text-black text-white items-center rounded-full border border-transparen px-[16px] py-[8px] text-base font-bold shadow-sm focus:outline-none [@media(max-width:640px)]:w-[100%] [@media(max-width:640px)]:justify-center">
                            Add experience<span className="ml-3"><Plus /></span>
                        </button>
                    </form>
                    <div className='mt-[24px]'>
                        <ul className='flex flex-col gap-[16px]'>
                            {experience.length >0 &&experience.map((data, j) => (
                            <React.Fragment key={j}>
                                <li className='border-[1px] border-[#D9D9D9] dark:border-[#808080] dark:text-[#FFFFFF] dark:bg-[#1D1D1D] text-[#07091B] bg-[#FFFFFF] rounded-[6px] px-[16px] justify-between py-[12px] font-medium flex leading-8 gap-[6px]'>
                                <div className='w-[20%] hidden'>
                                    {/* <img className="flagSVG" src={`https://static.carrousela.com/app/awards/${data['company_id']}.svg`} alt="flag" /> */}
                                </div>
                                <div className='flex flex-col w-[85%]'>
                                    <span className='font-bold text-[16px] flex items-center w-[100%] break-normal '>{getLookup(data, 'roles_id')}</span>
                                    <span className='font-regular archivo text-[12px] flex items-center w-[100%] break-normal '>{getLookup(data, 'company_id')}</span>
                                    <span className='font-regular archivo text-[12px] flex items-center w-[100%] break-normal '>{(data['started_at'] || data['start_at']) ? getFullDate(new Date((data['started_at'] || data['start_at'])), 'start') : ""}{data.is_current ? " - Present" : (data['ended_at'] || data['end_at']) ? " - " + getFullDate(new Date((data['ended_at'] || data['end_at']))) : ''}</span>
                                </div>
                                <div className='float-right flex gap-[8px]'>
                                    <button onClick={() => editValue(data, j)} className="flex justify-center m-auto">
                                    <span className="m-[4px]"><Edit /></span>
                                    </button>
                                    <button onClick={() => deleteValue(j)} className="flex justify-center m-auto">
                                    <span className="m-[4px]"><Cancel /></span>
                                    </button>
                                </div>
                                </li>
                            </React.Fragment>
                            ))}
                        </ul>
                    </div>
                </div>
            </ClientOnboardingTemplate>
            <FooterWithBackNext prevStep={props.prevStep} submitHandler={submitHandler} showSkip={true} skipStep={props.skipStep} persentage="28.6%" text="3/7"></FooterWithBackNext>
            {showPopup && <ExperiencePopup close={closePopup} save={saveValues} value={experienceEdit} />}
        </div>
    )
}