import { apiService } from '../../utils/service';
import rendertoast from '../../utils/rendertoast';
import { GetCompanies } from './commonapi';
//Api to update/save the talent onboarding form details
export const FormDataUpdateTalent = (data) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "talent/update",
            data: data,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get market drop-down options
export const GetMarket = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.market.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "market/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "market" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {
        return false
    }
}
//Api to get pronouns drop-down options
export const GetPronouns = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.pronouns.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "pronouns/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "pronouns" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {
        return false
    }
}
export const GetYearStarted = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.yearstarted.length === 0) {
            var today = new Date(), beforeYears = 40, thisyear = today.getFullYear(), yearList = [];
            for (var year = thisyear - beforeYears, index = 0;
                index <= beforeYears;
                index++, year++) {
                yearList.push({ "label": year, "value": year })
            }
            let yrList = yearList.reverse();
            dispatch({ type: 'drop_down_option/insertValue', payload: { "value": yrList, "name": "yearstartedcareer" } });
        }
    } catch (error) {

    }
}
//Api to get company drop-down options
export const GetDefaultCompanies = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.defaultCompanies.length === 0) {
            GetCompanies("").then((res) => {
                if (res) {
                    const options = res.map(i => ({
                        label: i.label,
                        value: i.company_id,
                        company_id: i.company_id,
                        logo: i.logo,
                    }));
                    dispatch({ type: 'drop_down_option/insertValue', payload: { "value": options, "name": "defaultCompanies" } });
                }
            })
        }
    } catch (error) {

    }
}
//Api to get levels drop-down options
export const GetLevels = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.level.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "level/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "level" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {
        return false
    }
}
//Api to get cultural identity drop-down options
export const GetCulturalIdentity = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.culturalIdentity.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "cultural-identity/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "culturalIdentity" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to invite user via email
export const inviteEmail = (email) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "talent/invite",
            data: { email },
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                rendertoast.showToast({ "msg": "Invite sent successfully" })
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get all the work of talent
export const getTalentWork = (dispatch, id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "work/get",
            data: {
                "filter": {
                    "talent_id": [
                        id
                    ]
                }
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    const filteredItems = res.data.works.filter(item => Boolean(item.roles) && item.roles.length && item.creatives && item.creatives.length)
                    if (filteredItems && filteredItems.length) {
                        dispatch({ type: 'talent_profile/insertValue', payload: { "id": { works: filteredItems }, "name": "tab6" } });
                        var awards = filteredItems.filter(item => 'awards' in item).map((awrds) => awrds.awards).flat();
                        var workWithAwd = filteredItems.filter(item => 'awards' in item).map((awrds) => awrds);
                        var modifiedObj = {}
                        workWithAwd.forEach((data, i) => {
                            data.awards.forEach(awd => {
                                modifiedObj[awd.award_id] = []
                            })
                        })
                        Object.entries(modifiedObj).forEach(ad => {
                            workWithAwd.forEach((data, i) => {
                                data.awards.forEach(awd => {
                                    if (ad[0] === awd.award_id) {
                                        ad[1].push(Object.assign({ client_id: data.client_id, work_id: data.work_id, company: data.company }, awd))
                                    }
                                })
                            })
                        })
                        dispatch({ type: 'talent_profile/insertValue', payload: { "id": { awards: awards }, "name": "tab3" } });
                        dispatch({ type: 'talent_profile/insertValue', payload: { "id": { awardList: modifiedObj }, "name": "tab8" } });
                    } else {
                        dispatch({ type: 'talent_profile/insertValue', payload: { "id": { works: [] }, "name": "tab6" } });
                        dispatch({ type: 'talent_profile/insertValue', payload: { "id": { awards: [] }, "name": "tab3" } });
                    }
                }
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get work details using work id
export const getTalentWorkById = (id, work_id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "work/get",
            data: {
                "filter": {
                    "talent_id": [
                        id
                    ],
                    "work_id": [work_id]
                }
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    return res.data.works
                }
                return null;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to upload profile image
export const ProfileUploadApi = (file) => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "talent/avatar/upload?type=" + file.type.replace("image/", ""),
            data: "",
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    return res;
                } else {
                    return "";
                }
            })
            .catch((err) => {
                // return res.data;
            })
    } catch (error) {

    }
}
//Api to get market drop-down options
export const GetLeadershipSkills = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.leadershipSkils.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "leadership/skill/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "leadershipSkils" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to get awards
export const GetAwards = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.awards.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "award/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "awards" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to get tone drop-down options
export const GetTone = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.tone.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "tone/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "tone" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to upload work
export const talentUploadWorkApi = (file) => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "talent/work/upload?type=" + file.type.replace("image/", ""),
            data: "",
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    return res;
                } else {
                    return "";
                }
            })
            .catch((err) => {
                // return res.data;
            })
    } catch (error) {

    }
}
//Api to reset the talent onboarding when we want to edit it after completion process
export const TalentUpdateReset = (body) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "talent/update/reset",
            data: body,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}