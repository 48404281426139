import React, { useEffect, useRef, useState } from 'react'
import minilogo from '../../../assets/img/logo-mini.svg';
import mlogo from "../../../assets/img/moon-new.png";
import placeholder from "../../../assets/img/moon_placeholder.svg";
import { useDispatch, useSelector } from 'react-redux';
import { GetBrands, GetDiversity, GetIndustry, GetSkills } from '../../service/commonapi';
import { GetTone } from '../../service/talent-onboardig-api';
import { useNavigate, useParams } from 'react-router-dom';
import grediant from "../../../assets/img/grediant.png"
import DynamicLoader from '../../../utils/dynamicLoader';
import { MatchingSummary } from './matching-summary/matching-summary';
import { GetAgencyById, GetProjectResults, GetTalentById, getAgencyWork, getTalentWork } from '../../service/project-setup-api';
import { EditIcon } from '../common/icons/edit-icon';
import common from '../../../utils/common';
import { AgencyMatchingSummary } from './matching-summary/agency-matching-summary';
import Rendertoast from '../../../utils/rendertoast';

export const MatchingSkills = () => {
    const [matching, setmatching] = useState("")
    const [percentage, setpercentage] = useState(0)
    const [percentage0, setpercentage0] = useState(0)
    const [percentage1, setpercentage1] = useState(0)
    const [percentage2, setpercentage2] = useState(0)
    const selectStore = useSelector(state => state.selectStore);
    const commonStore = useSelector(state => state.commonStore);
    const [showloader, setshowloader] = useState(false);
    const isMounted = useRef(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dummyData, setDummyData] = useState([])
    const [showsuperstars, setshowsuperstars] = useState(false)
    const [isLoading, setisLoading] = useState(true);
    const [showSummary, setshowSummary] = useState(false);
    const [showAgencySummary, setshowAgencySummary] = useState(false);
    const [results, setResults] = useState([])
    const [userDetails, setUserDetails] = useState([])
    const [talentDetails, setTalentDetails] = useState([])
    const [defaultTab, setDefaultTab] = useState(0);
    var { platform, pid } = useParams();
    useEffect(() => {
        if (isMounted.current) {
            setisLoading(true)
            getDummyData();
            projectResults(pid)
            callAllSelect();
            counter('progress-count', 0, 100, 9000, setpercentage)
        }
        sessionStorage.removeItem("nav_show_team")
    }, []);

    useEffect(() => {

        if (!isLoading) {
            addRotateAnimation()
            const heading = document.querySelector('.fade-in-animate');
            if (heading) {
                heading.classList.add('fady')
                setmatching("Skills")
            }
            setTimeout(() => {
                setDummyData(dummyData.slice(3, 6))
            }, 500)
            setTimeout(() => {
                setDummyData(dummyData.slice(6, 9))
            }, 2500)
            setTimeout(() => {
                setDummyData(dummyData.slice(9, 13))
            }, 4500)
            setTimeout(() => {
                removeRotateAnimation(0)
                setmatching("Portfolios");
            }, 2000)
            setTimeout(() => {
                setDummyData(dummyData.slice(12, 15))
            }, 6500)
            setTimeout(() => {
                addRotateAnimation()
                removeRotateAnimation(2)
                setmatching("Industries")
            }, 4000)

            setTimeout(() => {
                addRotateAnimation()
                removeRotateAnimation(0)
                removeRotateAnimation(1)
                setmatching("Tone of Voice")
            }, 6000)

        }
    }, [isLoading])

    function projectResults(id) {
        var _id = id ? common.encryptdecrypt("decrypt",id) : null;
        GetProjectResults(dispatch, _id).then(res => {
            console.log(res)
            if (res && res.data && res.data.results) {
                var results = res.data.results;
                if (results && results.providers) {
                    if (platform === 'agency') {
                        var data = results.providers.filter((val) => val.type === 'agency')
                        delete results.providers;
                        results['data'] = data;
                        // console.log(results)
                        setResults(results);
                        getUserData(results);
                        ratingpercentage("agency",results)
                    } else if (platform === 'talent') {
                        var datatal = results.providers.filter((val) => val.type === 'talent');
                        delete results.providers
                        results['data'] = datatal;
                        if (datatal.length === 6) {
                            let newData = [{ Talents: [datatal[0], datatal[1]] }, { Talents: [datatal[2], datatal[3]] }, { Talents: [datatal[4], datatal[5]] }]
                            setTalentDetails(newData)
                            setResults(results);
                            getUserData(results);
                            ratingpercentage("talent",results)
                            
                        }
                    }
                }
            }
        })
    }
    function ratingpercentage(type,_results){
        
        var percentage0 = 0;
        var percentage1 = 0;
        var percentage2 = 0;
        if(type==="agency"){
             percentage0 =_results.data[0].match_rate * 100;
             percentage0 = Math.round(percentage0);
             percentage1 =_results.data[1].match_rate * 100;
             percentage1 = Math.round(percentage1);
             percentage2 =_results.data[2].match_rate * 100;
             percentage2 = Math.round(percentage2);
        }else{
            percentage0 = _results.data[0].match_rate + _results.data[1].match_rate;
            percentage0 = percentage0/2
            percentage0 = percentage0*100;
            percentage0 = Math.round(percentage0);
            percentage1 = _results.data[2].match_rate + _results.data[3].match_rate;
            percentage1 = percentage1/2
            percentage1 = percentage1*100;
            percentage1 = Math.round(percentage1);
            percentage2 = _results.data[4].match_rate + _results.data[5].match_rate;
            percentage2 = percentage2/2
            percentage2 = percentage2*100;           
            percentage2 = Math.round(percentage2);

        }
        if (!showloader) {
            setTimeout(() => {
                const cardContainer = document.querySelectorAll(".card-container");
                if (cardContainer && cardContainer.length) {
                    [...cardContainer].forEach(div => {
                        setshowloader(true)
                        setTimeout(() => {
                            setshowloader(false)
                        }, 5000)
                        div.classList.add('animate-flip')
                        div.classList.remove('fliping')
                        // setDummyData(dummyData.slice(16, 19))
                        setshowsuperstars(true);
                        const rating = document.querySelectorAll(".superstar-rating");
                        setTimeout(() => {
                            if (rating) {
                                [...rating].forEach(div1 => {
                                    div1.classList.add('opacity-1')
                                });
                            }
                            counter('progress-count_0', 0, percentage0, 1000, setpercentage0)
                            counter('progress-count_1', 0, percentage1, 1000, setpercentage1)
                            counter('progress-count_2', 0, percentage2, 1000, setpercentage2)
                        }, 5001)
                    });
                }
            }, 8000)
        }
    }
    function getUserData(val) {
        var ids = []
        val.data.forEach(tal => {
            var idIndex = ids.findIndex(val => val === tal.id);
            if (idIndex === -1) {
                ids.push(tal.id)
            }
        })
        if (ids && ids.length) {
            var useDet = [...userDetails]
            ids.forEach(id => {
                if (platform === 'agency') {
                    var leaders = []
                    var ddt = {
                        "agency_id": null,
                        "email": null,
                        "fullname": null,
                        "leader_id": null,
                        "linkedin_url": null,
                        "profile_avatar_url": null,
                        "role": null,
                        "years_of_experience": 0
                    }
                    GetAgencyById(id).then(async (res) => {
                        if (res && res.agencies && res.agencies.length) {
                            if (!res.agencies[0].leaders || (res.agencies.leaders && !res.agencies.leaders.length)) {
                                leaders.push(ddt)
                                leaders.push(ddt)
                            }
                            else {
                                leaders.push(res.agencies[0].leaders[0] ? res.agencies[0].leaders[0] : ddt)
                                leaders.push(res.agencies[0].leaders[1] ? res.agencies[0].leaders[1] : ddt)
                            }
                            await getAgencyWork(id).then((resp) => {
                                try {
                                    if (resp) {
                                        res.agencies[0]['works'] = resp && resp.works ? resp.works : [];
                                        res.agencies[0]['awards'] = resp && resp.awards ? resp.awards : []
                                    }
                                    res.agencies[0]['leaders'] = leaders
                                    useDet.push(res.agencies[0]);
                                } catch (e) {
                                    console.log('er', e)
                                    res.agencies[0]['leaders'] = leaders
                                    useDet.push(res.agencies[0]);
                                }

                            })
                        }
                    })
                } else {
                    GetTalentById(id).then(async (res) => {
                        if (res && res.users && res.users.length) {
                            await getTalentWork(id).then((resp) => {
                                res.users[0]['works'] = resp && resp.works ? resp.works : [];
                                res.users[0]['awards'] = resp && resp.awards ? resp.awards : [];
                                useDet.push(res.users[0]);
                            })

                        }
                    })
                }
                setUserDetails(useDet)
            });

        }
    }


    function addRotateAnimation(index) {
        var allCardElements = document.querySelectorAll('.card-rotate');
        if (allCardElements && allCardElements.length) {
            var elements = document.getElementsByClassName('card-container');
            [...elements].forEach(card => {
                if (!card.classList.contains('fliping')) {
                    card.classList.add('fliping')
                }
            })
        }
    }


    function removeRotateAnimation(index) {
        if (index !== -1 || index !== undefined) {
            var cardContainer = document.querySelectorAll(`.cards-${index}`);
            if (cardContainer && cardContainer.length > 0)
                var animateElements = cardContainer[0] ? cardContainer[0].getElementsByClassName('fliping') : null;
            var shadowElements = cardContainer[0] ? cardContainer[0].getElementsByClassName('shadow-rotate') : null;
            if (animateElements) {
                [...animateElements].forEach(div => {
                    div.classList.remove('fliping');
                });
            }
            if (shadowElements) {
                [...shadowElements].forEach(ele => {
                    ele.classList.remove('shadow-rotate');
                });
            }
        }
    }


    function getDummyData() {
        fetch(platform === 'agency' ? '../../json/matching-skills.json' : '../../json/matching-skills-talent.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then((res) => res.json()).then((_res) => {
            if (_res && _res.length) {
                setDummyData(_res);
                setisLoading(false)
            }
        })
        setDummyData(dummyData.slice(0, 3))
    }

    function counter(id, start, end, duration, state) {
        let obj = document.getElementById(id),
            current = start,
            range = end - start,
            increment = end > start ? 1 : -1,
            step = Math.abs(Math.floor(duration / range)),
            timer = setInterval(() => {
                current += increment;
                if (obj)
                    obj.textContent = current + '%';
                state(current)
                if (current === end) {
                    clearInterval(timer)
                }
            }, step)
    }

    useEffect(() => {
        if (commonStore.theme) {
            if (commonStore.theme === 'light') {
                document.body.classList.remove('dark')
            } else {
                document.body.classList.add(commonStore.theme);
            }
        }
    }, [commonStore.theme])



    function callAllSelect() {
        try {
            GetDiversity(dispatch, selectStore)
            GetSkills(dispatch, selectStore)
            GetIndustry(dispatch, selectStore)
            GetBrands(dispatch, selectStore)
            GetTone(dispatch, selectStore)
        } catch (error) {
        }
    }

    function editProject(e) {
        if (pid && pid !== "undefined") {
            navigate(`/home/project-setup?` + pid)
        }
    }


    function cardHandler(e, index, data) {
        if (data || (data.Talents && data.Talents.length)) {
            setDefaultTab(index)
            setTimeout(() => {
                window.scrollTo(0, 900)
            }, 500)
            if (platform === "talent") {
                [...document.querySelectorAll(".test")].forEach(_node => {
                    _node.classList.remove("active-card")
                })
                document.querySelectorAll(`.cards-${index}`)[0].children[1].classList.add("active-card")
                setshowSummary(true)
            } else {
                setshowAgencySummary(true);
                [...document.querySelectorAll(".test")].forEach(_node => {
                    _node.classList.remove("active-card-agency")
                })
                document.querySelectorAll(`.cards-${index}`)[0].children[1].classList.add("active-card-agency")
            }
        } else {
            Rendertoast.showToast({ "type": "e", "msg": 'Invalid ID' });
        }
    }

    function getTalentDetails(data) {
        setTalentDetails(data)
    }

    return (
        <>
            {<div className='bg-[#f5f5f5] dark:bg-[#1d1d1d] w-full h-full matching-skills-container'>
                <div className="flex flex-row gap-[16px] px-[88px] py-[28px] mobile:h-[64px] tablet:py-[5px] tablet:px-[24px]  tablet:dark:bg-[#0E0E0E] justify-between items-center">
                    <div className='flex flex-row'>
                        <img alt="mlogo" className="tablet:p-[10px] [@media(min-width:767px)]:hidden block dark:hidden" src={minilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:hidden dark:hidden h-[46px]" src={mlogo} height="50px" />
                    </div>
                    {showsuperstars && <div className=''>
                        <button onClick={editProject} className='flex flex-row gap-[16px] mobile:gap-[8px] items-center text-[24px] tracking-[-0.72px] leading-[28.8px] text-[#1D1D1D] dark:text-[#FFF] mobile:text-[15px]'><EditIcon /> Edit project</button>
                    </div>}
                </div>
                <div>
                    <div className="wrapper w-full h-[58px] text-center ">
                        {!showsuperstars && <h1 className="w-[100%] dark:text-white [@media(max-width:767px)]:text-[24px] text-[48px] font-extralight fade-in-animate">Matching <b className='text-[48px] dark:text-white [@media(max-width:767px)]:text-[24px] w-full font-bold '>{matching}</b></h1>}{showsuperstars && <h1 className="zoom-in-out zoom text-[48px] font-bold text-[#1D1D1D] dark:text-white tracking-[-1.44px] leading-[57.6px] mobile:text-[30px]">Superstars. Aligned.</h1>}
                    </div>
                    <div className={`w-full flex text-center justify-center pt-[16px] ${showsuperstars ? 'opacity-1' : 'opacity-0'}`}>
                        <p className='w-[500px] text-[18px] text-[#1D1D1D] tracking-[-0.54px] dark:text-white  leading-[21.6px] mobile:text-[16px]'>We’ve found three teams equipped to take on your mission.</p>
                    </div>
                    <div className={`${showsuperstars ? 'min-h-[383px]' : ''} [@media(min-width:1350px)]:max-w-[1356px] max-w-[750px] [@media(max-width:767px)]:max-w-[350px] mx-auto [@media(max-width:767px)]:my-[50px] !mb-[35px] !mt-[100px] mobile:!mt-[20px]`}>
                        <div className=' overflow-hidden h-full'>
                            <div className={`flex [@media(min-width:1350px)]:gap-[124px] card-rotate gap-[16.5px]`}>
                                {(!showsuperstars) && dummyData.map((data, i) => {
                                    return (
                                        <div key={i} className={`cards-${i}`}>
                                            {platform === 'agency' &&
                                                <div className='text-[24px] flex dark:text-white items-center gap-[10px] justify-center text-center font-bold mb-[32px]'><img className='w-[36px] h-[36px]' src="../../images/defLogo.svg" alt="defLogo"></img>{data.company_name ? data.company_name : "Company name"}</div>}
                                            {platform === 'talent' &&
                                                <div className='text-[24px] text-center font-bold mb-[32px]'>{data.title ? data.title : "Title"}</div>}
                                            <div
                                                // onMouseOver={() => mouseHandler(i)} onMouseLeave={() => leaveHandler(i)} 
                                                onClick={(e) => cardHandler(e, i)} className={`${showsuperstars ? 'justify-center items-center' : ''} mt-[20px] flex test`}>
                                                {data.talents.map((_data, _i) => {
                                                    return (
                                                        <div key={_i} className={`relative card-container flip-card-inner cardy mb-[32px] mobile:mb-[10px]`}>
                                                            <div className={` relative flip-card-front flex flex-col justify-between gap-[24px] px-[16px] py-[24px] items-center ${platform === 'talent' ? 'card-img-talent' : 'card-img'}`}>
                                                                <div className='text-[14px] dark:text-black font-bold name text-center leading-[14px] tracking-[-0.42px] h-[28px] text-ellipsis overflow-hidden w-[141px]' title={_data.name}>{_data.name ? _data.name : "Name"}</div>
                                                                <span className={`w-[120px] h-[120px] bg-[#a3a3a3] rounded-[50%] relative -top-[10px]`}><div className='w-full h-full bg-cover bg-no-repeat rounded-[50%] bg-center' style={{ backgroundImage: `url(../../images/matching-skills/${_data.img}.png)` }}></div></span>
                                                                {/* <span className={`w-[120px] h-[120px] rounded-full relative -top-[10px] bg-cover`} style={{ backgroundImage: `url(../../images/matching-skills/${_data.img}.png)` }}></span> */}
                                                                {/* <img src={`../../images/matching-skills/${_data.img}.png`} className={`w-[120px] h-[120px] ${platform==="agency" ? "" : ""} rounded-full relative -top-[10px]`} alt="card" /> */}
                                                                <div className='text-[14px] tracking-[-0.42px] leading-[100%] text-[#808080] role max-w-[120px] text-center capitalize max-h-[40px] text-ellipsis overflow-hidden' title={_data.role}>{_data.role ? _data.role : "Role"}</div>
                                                            </div>
                                                            <div className={` flip-card-back absolute z-10 flex-col px-[16px] py-[24px] items-center gap-[24px] top-0 flex justify-between ${platform === 'talent' ? 'card-img-talent' : 'card-img '}`}>
                                                                {/* <div className='cus flex flex-col px-[16px] py-[24px] items-center gap-[24px] dark:text-black justify-between w-full '> */}
                                                                <div className='text-[14px] font-bold name text-center leading-[14px] tracking-[-0.42px] h-[28px] text-ellipsis overflow-hidden w-[141px]' title={_data.name}>{_data.name ? _data.name : "Name"}</div>
                                                                <span className={`w-[120px] h-[120px] bg-[#a3a3a3] rounded-[50%] relative -top-[10px]`}><div className='w-full h-full bg-cover bg-no-repeat rounded-[50%] bg-center' style={{ backgroundImage: `url(../../images/matching-skills/${_data.img}.png)` }}></div></span>
                                                                {/* <img src={`../../images/matching-skills/${_data.img}.png`} className={`w-[120px] ${platform==="agency" ? "" : ""} h-[120px] rounded-full relative -top-[10px]`} alt="card" /> */}
                                                                <div className='text-[14px] tracking-[-0.42px] leading-[100%] text-[#808080] role max-w-[120px] text-center capitalize max-h-[40px] text-ellipsis overflow-hidden' title={_data.role}>{_data.role ? _data.role : "Role"}</div>
                                                            </div>
                                                            {/* </div> */}
                                                            <img alt="shadow" src={grediant} className={`${!showsuperstars ? 'shado' : ''} mt-[32px] rotating-180`} />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {showsuperstars && <div className='flex flex-col items-center superstar-rating matching-animate opacity-0'>
                                                <div className='text-[19.942px] leading-[100%] tracking-[-0.598px] capitalize text-[#0E0E0E] mobile:text-[16px] dark:text-white'>Match Rating</div>
                                                <div className="content [@media(max-width:767px)]:w-full">
                                                    <div className='loading py-[8px] flex items-center gap-[24px] w-[336px] mobile:justify-center'>
                                                        <div className="progress mb-[0] w-[215px] float-left">
                                                            <span id={`myprogressBar_${i}`} className="progress-bar custom-transition cool" style={{ width: `${i === 0 ? percentage0 : i === 1 ? percentage1 : percentage2}%` }}></span>
                                                        </div>
                                                        <div id={`progress-count_${i}`} className={`text-[48px] float-right leading-[100%] tracking-[-1.44px] mobile:text-[29px]`}>0%</div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    )
                                })}
                                {showsuperstars && platform === 'agency' && userDetails.map((data, i) => {
                                    return (
                                        <div key={i} className={`cards-${i}`}>
                                            {platform === 'agency' &&
                                                <div className='text-[24px] dark:text-white flex gap-[15px] justify-center  text-center font-bold mb-[32px]'><img className='w-[36px] h-[36px]' src={"../../images/defLogo.svg"} alt="defLogo"></img> {data.name ? data.name : "Name"}</div>}
                                            {platform === 'talent' &&
                                                <div className='text-[24px] text-center font-bold mb-[32px]'>{data.name ? data.name : "Name"}</div>}
                                            <div
                                                // onMouseOver={() => mouseHandler(i)} onMouseLeave={() => leaveHandler(i)} 
                                                onClick={(e) => cardHandler(e, i, data)} className={`${showsuperstars ? `justify-center ${platform === 'talent' ? "cardy-talent" : "cardy-agency"} items-center min-h-[388px]` : ''} mt-[20px] flex test`}>
                                                {data.leaders && data.leaders.map((_data, _i) => {
                                                    return (
                                                        <div key={_i} className={`relative cardy  mb-[32px] mobile:mb-[10px]`}>
                                                            <div className={`card-container relative z-10 back-hide animate-flip flex flex-col justify-between gap-[24px] px-[16px] py-[24px] items-center  ${platform === 'talent' ? 'card-img-talent' : 'card-img'}`}>
                                                                <div className='text-[14px] dark:text-black font-bold name px-[16px] text-center leading-[14px] tracking-[-0.42px] h-[28px] text-ellipsis overflow-hidden w-[141px]' title={_data.fullname}>{_data.fullname ? _data.fullname : "Name"}</div>
                                                                {<span className={`w-[120px] h-[120px] bg-[#a3a3a3] rounded-[50%] relative -top-[10px]`}><div className='w-full h-full bg-cover bg-no-repeat rounded-[50%] bg-center' style={{ backgroundImage: _data.profile_avatar_url ? `url(${_data.profile_avatar_url})` : `url(${placeholder})` }}></div></span>}
                                                                {/* {_data.profile_avatar_url ? <img src={`../../images/matching-skills/${_data.profile_avatar_url}.png`} className='w-[120px] h-[120px] rounded-full relative -top-[10px]' alt="card" /> : <span className={`w-[120px] bg-[#a3a3a3] ${platform==="agency" ? "" : ""} h-[120px] rounded-full relative -top-[10px]`} ><svg width="100%" height="100%" className='rounded-full' viewBox="0 0 237 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g id="Group 8098">
                                                                        <rect id="Rectangle 3107" x="29.6797" y="32.5879" width="180.056" height="142.019" fill="#FBFBFB" />
                                                                        <path id="Vector" d="M191.767 0.544922C164.25 0.544922 0.474609 1.64242 0.474609 0.544922C0.474609 0.544922 0.474609 140.429 0.474609 159.065C0.474609 179.841 23.5318 183.984 25.6334 184.32C25.8128 186.022 28.9779 211.634 57.7076 211.634C72.0169 211.634 236.894 211.634 236.894 211.634C236.894 211.634 236.894 72.4181 236.894 46.5844C236.894 16.9903 219.283 0.544922 191.767 0.544922ZM54.3161 146.265H38.405C38.405 146.265 38.405 91.8454 38.405 82.891C38.405 64.2932 54.3161 64.2932 54.3161 64.2932V146.269V146.265ZM185.359 146.265V64.2932H114.031C115.047 64.3145 129.886 64.9313 129.886 82.891V146.265H113.975V64.2932H54.4997C54.4997 64.2932 54.4997 48.4476 73.1745 48.4476H94.1856C108.328 48.4476 111.758 57.5296 112.596 61.9408C113.428 57.5296 116.863 48.4476 131.005 48.4476H167.061C185.735 48.4476 185.735 64.2932 185.735 64.2932H185.415C186.432 64.3145 201.266 64.9313 201.266 82.891C201.266 91.8454 201.266 146.265 201.266 146.265H185.355H185.359Z" fill="#A3A3A3" />
                                                                    </g>
                                                                </svg></span>} */}
                                                                <div className='text-[14px] tracking-[-0.42px] leading-[100%] text-[#808080] role max-w-[120px] text-center capitalize max-h-[40px] text-ellipsis overflow-hidden' title={_data.role}>{_data.role ? _data.role : "Your role"}</div>
                                                            </div>
                                                            <div className={`card-container animate-flip absolute top-0 flex justify-between ${platform === 'talent' ? 'card-img-talent' : 'card-img card-img-reverse'}`}>
                                                                <div className='cus flex flex-col px-[16px] py-[24px] items-center gap-[24px] dark:text-black justify-between w-full '>
                                                                    <div className='text-[14px] font-bold name px-[16px] text-center leading-[14px] tracking-[-0.42px] h-[28px] text-ellipsis overflow-hidden w-[141px]' title={_data.fullname}>{_data.fullname ? _data.fullname : "Name"}</div>
                                                                    {/* {_data.profile_avatar_url ? <img src={`../../images/matching-skills/${_data.profile_avatar_url}.png`} className='w-[120px] h-[120px] rounded-full relative -top-[10px]' alt="card" /> : <span className='bg-[#a3a3a3] w-[120px] h-[120px] rounded-full relative -top-[10px]' ></span>} */}
                                                                    {/* {<span className={`w-[120px] h-[120px] bg-[#a3a3a3] rounded-[50%] relative -top-[10px]`}><div className='w-full h-full bg-cover bg-no-repeat rounded-[50%] bg-center' style={{ backgroundImage: _data.photo_url ? `url(${_data.photo_url})` : `url(${placeholder})` }}></div></span>} */}
                                                                    {<span className={`w-[120px] h-[120px] bg-[#a3a3a3] rounded-[50%] relative -top-[10px]`}><div className='w-full h-full bg-cover bg-no-repeat rounded-[50%] bg-center' style={{ backgroundImage: _data.profile_avatar_url ? `url(${_data.profile_avatar_url})` : `url(${placeholder})` }}></div></span>}
                                                                    <div className='text-[14px] tracking-[-0.42px] leading-[100%] text-[#808080] role max-w-[120px] text-center capitalize max-h-[40px] text-ellipsis overflow-hidden' title={_data.role}>{_data.role ? _data.role : "Your role"}</div>
                                                                </div>
                                                            </div>
                                                            <img alt="shadow" src={grediant} className={`${!showsuperstars ? 'shadow-rotate' : ''} mt-[32px]`} />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {showsuperstars && <div className='flex flex-col items-center superstar-rating matching-animate opacity-0'>
                                                <div className='text-[19.942px] leading-[100%] tracking-[-0.598px] capitalize text-[#0E0E0E] mobile:text-[16px] dark:text-white'>Match Rating</div>
                                                <div className="content [@media(max-width:767px)]:w-full">
                                                    <div className='loading py-[8px] flex items-center gap-[24px] w-[336px] mobile:justify-center'>
                                                        <div className="progress mb-[0] w-[215px] float-left">
                                                            <span id={`myprogressBar_${i}`} className="progress-bar custom-transition cool" style={{ width: `${i === 0 ? percentage0 : i === 1 ? percentage1 : percentage2}%` }}></span>
                                                        </div>
                                                        <div id={`progress-count_${i}`} className={`text-[48px] float-right leading-[100%] tracking-[-1.44px] mobile:text-[29px]`}>0%</div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    )
                                })}
                                {showsuperstars && platform === 'talent' && talentDetails.map((data, i) => {
                                    return (
                                        <div key={i} className={`cards-${i}`}>
                                            <div className='text-[24px] text-center font-bold mb-[32px] h-[36px] truncate w-[360px]' title={common.getTalentName(data.Talents, userDetails)}>{common.getTalentName(data.Talents, userDetails)}</div>
                                            <div
                                                // onMouseOver={() => mouseHandler(i)} onMouseLeave={() => leaveHandler(i)} 
                                                onClick={(e) => cardHandler(e, i, data)} className={`${showsuperstars ? 'justify-center cardy-talent items-center min-h-[388px]' : ''} mt-[20px] flex test`}>
                                                {data.Talents.map((_data, _i) => {
                                                    return (
                                                        <div key={_i} className={`relative cardy  mb-[32px] mobile:mb-[10px]`}>
                                                            <div className={`card-container relative z-10 back-hide animate-flip flex flex-col justify-between gap-[24px] px-[16px] py-[24px] items-center ${platform === 'talent' ? 'card-img-talent' : 'card-img'}`}>
                                                                <div className='text-[14px] dark:text-black font-bold name px-[16px] text-center leading-[14px] tracking-[-0.42px] h-[28px] text-ellipsis overflow-hidden w-[141px]' title={_data.name}>{_data.name ? _data.name.split(" ")[0] : "Name"}</div>
                                                                {/* {_data.photo_url ? <img src={_data.photo_url} className='w-[120px] h-[120px] rounded-full relative -top-[10px]' alt="card" /> : <span className='w-[120px] bg-[#a3a3a3] h-[120px] rounded-full relative -top-[10px]' ><svg width="100%" height="100%" className='rounded-full' viewBox="0 0 237 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g id="Group 8098">
                                                                        <rect id="Rectangle 3107" x="29.6797" y="32.5879" width="180.056" height="142.019" fill="#FBFBFB" />
                                                                        <path id="Vector" d="M191.767 0.544922C164.25 0.544922 0.474609 1.64242 0.474609 0.544922C0.474609 0.544922 0.474609 140.429 0.474609 159.065C0.474609 179.841 23.5318 183.984 25.6334 184.32C25.8128 186.022 28.9779 211.634 57.7076 211.634C72.0169 211.634 236.894 211.634 236.894 211.634C236.894 211.634 236.894 72.4181 236.894 46.5844C236.894 16.9903 219.283 0.544922 191.767 0.544922ZM54.3161 146.265H38.405C38.405 146.265 38.405 91.8454 38.405 82.891C38.405 64.2932 54.3161 64.2932 54.3161 64.2932V146.269V146.265ZM185.359 146.265V64.2932H114.031C115.047 64.3145 129.886 64.9313 129.886 82.891V146.265H113.975V64.2932H54.4997C54.4997 64.2932 54.4997 48.4476 73.1745 48.4476H94.1856C108.328 48.4476 111.758 57.5296 112.596 61.9408C113.428 57.5296 116.863 48.4476 131.005 48.4476H167.061C185.735 48.4476 185.735 64.2932 185.735 64.2932H185.415C186.432 64.3145 201.266 64.9313 201.266 82.891C201.266 91.8454 201.266 146.265 201.266 146.265H185.355H185.359Z" fill="#A3A3A3" />
                                                                    </g>
                                                                </svg></span>} */}
                                                                {<span className={`w-[120px] h-[120px] bg-[#a3a3a3] rounded-[50%] relative -top-[10px]`}><div className='w-full h-full bg-cover bg-no-repeat rounded-[50%] bg-center' style={{ backgroundImage: _data.photo_url ? `url(${_data.photo_url})` : `url(${placeholder})` }}></div></span>}
                                                                <div className='text-[14px] tracking-[-0.42px] leading-[100%] text-[#808080] role max-w-[120px] text-center capitalize max-h-[40px] text-ellipsis overflow-hidden' title={_data.role}>{_data.role ? _data.role : "Your role"}</div>
                                                            </div>
                                                            <div className={`card-container animate-flip absolute top-0 flex justify-between ${platform === 'talent' ? 'card-img-talent' : 'card-img card-img-reverse'}`}>
                                                                <div className='cus flex flex-col px-[16px] py-[24px] items-center gap-[24px] dark:text-black justify-between w-full '>
                                                                    <div className='text-[14px] font-bold name px-[16px] text-center leading-[14px] tracking-[-0.42px] h-[28px] text-ellipsis overflow-hidden w-[141px]' title={_data.fullname}>{_data.fullname ? _data.fullname : "Name"}</div>
                                                                    {/* {_data.profile_avatar_url ? <img src={_data.photo_url} className='w-[120px] h-[120px] rounded-full relative -top-[10px]' alt="card" /> : <span className='w-[120px] bg-[#a3a3a3] h-[120px] rounded-full relative -top-[10px]' ><svg width="100%" height="100%" className='rounded-full' viewBox="0 0 237 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g id="Group 8098">
                                                                            <rect id="Rectangle 3107" x="29.6797" y="32.5879" width="180.056" height="142.019" fill="#FBFBFB" />
                                                                            <path id="Vector" d="M191.767 0.544922C164.25 0.544922 0.474609 1.64242 0.474609 0.544922C0.474609 0.544922 0.474609 140.429 0.474609 159.065C0.474609 179.841 23.5318 183.984 25.6334 184.32C25.8128 186.022 28.9779 211.634 57.7076 211.634C72.0169 211.634 236.894 211.634 236.894 211.634C236.894 211.634 236.894 72.4181 236.894 46.5844C236.894 16.9903 219.283 0.544922 191.767 0.544922ZM54.3161 146.265H38.405C38.405 146.265 38.405 91.8454 38.405 82.891C38.405 64.2932 54.3161 64.2932 54.3161 64.2932V146.269V146.265ZM185.359 146.265V64.2932H114.031C115.047 64.3145 129.886 64.9313 129.886 82.891V146.265H113.975V64.2932H54.4997C54.4997 64.2932 54.4997 48.4476 73.1745 48.4476H94.1856C108.328 48.4476 111.758 57.5296 112.596 61.9408C113.428 57.5296 116.863 48.4476 131.005 48.4476H167.061C185.735 48.4476 185.735 64.2932 185.735 64.2932H185.415C186.432 64.3145 201.266 64.9313 201.266 82.891C201.266 91.8454 201.266 146.265 201.266 146.265H185.355H185.359Z" fill="#A3A3A3" />
                                                                        </g>
                                                                    </svg></span>} */}
                                                                    {<span className={`w-[120px] h-[120px] bg-[#a3a3a3] rounded-[50%] relative -top-[10px]`}><div className='w-full h-full bg-cover bg-no-repeat rounded-[50%] bg-center' style={{ backgroundImage: _data.photo_url ? `url(${_data.photo_url})` : `url(${placeholder})` }}></div></span>}
                                                                    <div className='text-[14px] tracking-[-0.42px] leading-[100%] text-[#808080] role max-w-[120px] text-center capitalize max-h-[40px] text-ellipsis overflow-hidden' title={_data.role}>{_data.role ? _data.role : "Your role"}</div>
                                                                </div>
                                                            </div>
                                                            <img alt="shadow" src={grediant} className={`${!showsuperstars ? 'shadow-rotate' : ''} mt-[32px]`} />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {showsuperstars && <div className='flex flex-col items-center superstar-rating matching-animate opacity-0'>
                                                <div className='text-[19.942px] leading-[100%] tracking-[-0.598px] capitalize text-[#0E0E0E] mobile:text-[16px] dark:text-white'>Match Rating</div>
                                                <div className="content [@media(max-width:767px)]:w-full">
                                                    <div className='loading py-[8px] flex items-center gap-[24px] w-[336px] mobile:justify-center'>
                                                        <div className="progress mb-[0] w-[215px] float-left">
                                                            <span id={`myprogressBar_${i}`} className="progress-bar custom-transition cool" style={{ width: `${i === 0 ? percentage0 : i === 1 ? percentage1 : percentage2}%` }}></span>
                                                        </div>
                                                        <div id={`progress-count_${i}`} className={`text-[48px] float-right leading-[100%] tracking-[-1.44px] mobile:text-[29px]`}>0%</div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {!showsuperstars && <div className='hidden w-full flex-col items-center [@media(max-width:767px)]:px-[48px] justify-center'>
                        <p className='w-[500px] [@media(max-width:767px)]:w-full text-[#a3a3a3]'>Enim aliquip do consectetur esse. Aute nostrud nulla qui cillum sit. Commodo adipisicing sunt enim incididunt est Lorem eiusmod. Officia ex officia sit dolor amet fugiat non aute anim veniam amet ea officia.</p>
                        <div className="content [@media(max-width:767px)]:w-full py-[24px]">
                            <div className='loading [@media(max-width:767px)]:w-full    '>
                                <div className="progress">
                                    <span id="myprogressBar" className="progress-bar custom-transition cool" style={{ width: `${percentage}%` }}></span>
                                    <div id="progress-count" className={`font-bold pl-[50%] text-[16px] mt-[16px] `}></div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {/* {showsuperstars && <Matching/>} */}
                </div>
                {showloader && <DynamicLoader name="Login"></DynamicLoader>}
                {showsuperstars && <h3 className='text-[#1D1D1D] text-center text-[32px] mt-[64px]'>Select one to find out more.</h3>}
                {showSummary && <MatchingSummary defaultTab={defaultTab} getTalentDetails={getTalentDetails} userDetails={userDetails} results={results}/>}
                {showAgencySummary && <AgencyMatchingSummary  defaultTab={defaultTab} results={results} userDetails={userDetails}/>}
            </div>}
        </>
    )
}
