import React from 'react';
import { Search } from '../component/view/common/icons/search';
export default function FormSearchIcon(props) {
    return (
        <>
            {props.isArray
                ? ((props.isArray && Array.isArray(props.getval) && props.getval.length === 0) ? <span className="absolute bottom-[6px] right-[1px]"><Search /></span> : "")
                : (!props.getval || !props.fieldTouched[props.fieldname] || props.errors[props.fieldname] ? <span className="absolute bottom-[6px] right-[1px]"><Search /></span> : "")}
        </>
    )
}