import { useEffect, useRef, useState } from "react";
import FormValidationIcon from "../../../utils/form-validation-icon";
import { ClientOnboardingTemplate } from "../common/client-onboarding-template";
import { FooterWithBackNext } from "../common/footer-with-back-next";
import { Header } from "../common/header";
import { TextContainer } from "../common/text-container";
import { Controller, useForm } from "react-hook-form";
import FormSearchIcon from "../../../utils/form-search-icon";
import FormFieldError from "../../../utils/error-message";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Dropdown } from "../common/icons/dropdown";
import common from "../../../utils/common";

export const RoleAndIndustry = (props) => {
    const head = "Tell us your part?";
    const subHead = "Let's start with the basics.";
    const selectStore = useSelector(state => state.selectStore);
    const clientStore = useSelector(state => state.clientStore);
    const [location, setLocation] = useState();
    const [locationLabel, setLocationLabel] = useState(false);
    const [role,setRole] = useState();
    const [roleLabel, setRoleLabel] = useState(false);
    const [company,setCompany] = useState();
    const [companyLabel, setCompanyLabel] = useState(false);
    const [industryLabel, setIndustryLabel] = useState(false);
    const [industry, setIndustry] = useState();
    const headlineRef = useRef();
    const aboutRef = useRef();
    const dispatch = useDispatch();
    const { register, trigger, formState: { errors, touchedFields, isValid, dirtyFields }, setValue, getValues, control } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all"
    });

    useEffect(() => {
        Object.entries(clientStore.page2).forEach(val => {
            if (val[1] && (val[1] !== "" || (Array.isArray(val[1]) && val[1].length))) {
                if(val[0] === "location_id"){
                    var index = selectStore.location.findIndex(x => x.location_id === val[1]);
                    if (index !== -1) {
                        setLocation(selectStore.location[index]);
                        setLocationLabel(true)
                        setValue("location_id", val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                        setValue("location_id", val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                    }
                }else if(val[0] === "current_job_position"){
                    var index_ = selectStore.role.findIndex(x => x.role_id === val[1]);
                    if (index_ !== -1) {
                        setRole(selectStore.role[index_]);
                        setRoleLabel(true)
                        setValue("current_job_position", val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                        setValue("current_job_position", val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                    }
                }else if(val[0] === "company_id"){
                    var indx = selectStore.brands.findIndex(x => x.company_id === val[1]);
                    if (indx !== -1) {
                        setCompany(selectStore.brands[indx]);
                        setCompanyLabel(true)
                        setValue("company_id", val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                        setValue("company_id", val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                    }
                }else if(val[0] === "industry_id"){
                    var ind = selectStore.industry.findIndex(x => x.industry_id === val[1]);
                    if (ind !== -1) {
                        setIndustry(selectStore.industry[ind]);
                        setIndustryLabel(true)
                        setValue("industry_id", val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                        setValue("industry_id", val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                    }
                }
                else{
                    setValue(val[0], val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true, });
                }
            }
        })
    },[clientStore.page2, selectStore])
    
    function handleRoleOnChange(val) {
        try {
            if (val && Object.values(val).length) {
                setRoleLabel(true)
                setValue("current_job_position", val !== "" ? val.role_id : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setRole(val);
            } else {
                setRole();
                setValue("current_job_position", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setRoleLabel(false)
            }
        } catch (err) {
            console.log(err);
        }
    }

    function handleLocationOnchange(val) {
        try {
            if (val && Object.values(val).length) {
                setLocation(val);
                setLocationLabel(true);
                setValue("location_id", val !== "" ? val.location_id : "", { shouldTouch: true,  shouldValidate: true, shouldDirty: true, });
            }else {
                setLocation();
                setValue("location_id", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setLocationLabel(false)
            }
        } catch (err) {
          console.log(err);
        }
    }

    function handleCompanyOnChange(val) {
        try {
            if (val && Object.values(val).length) {
                setCompanyLabel(true)
                setValue("company_id", val !== "" ? val.company_id : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setCompany(val);
            } else {
                setCompany();
                setValue("company_id", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setCompanyLabel(false)
            }
        } catch (err) {
            console.log(err)
        }
    }
    
    function handleIndustryOnChange(val){
        try {
            if(val && Object.values(val).length){
                setIndustryLabel(true);
                setValue("industry_id", val !== "" ? val.industry_id : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setIndustry(val);
            }else{
                setIndustry();
                setValue("industry_id", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setIndustryLabel(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function textOnchangeSave(name) {
        try {
            var _data = JSON.parse(JSON.stringify(clientStore.page2));
            if ( getValues(name) !== "" && getValues(name) !== _data[name] &&  touchedFields[name] &&  !errors[name] ) {
                _data[name] = getValues(name);
                dispatch({  type: "client_details/insertValue", payload: { id: _data, name: "page2" } });
                var data = {};
                data[name] = getValues(name);
                props.saveData(data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    function submitHandler() {
        try {
          var _val = getValues();
          dispatch({ type: "client_details/insertValue",  payload: { id: _val, name: "page2" } });
          props.nextStep();
        } catch (err) {
          console.log(err);
        }
    }

    return(
        <div className="page-section">
            <Header isClient={true}></Header>
            <ClientOnboardingTemplate>
                <div className="role-textContainer">
                    <TextContainer head={head} subHead={subHead} activeTab="Role And Industry" activepersentage="14.28%" text="2/7"/>
                </div>
                <form className="form-layer basic-info space-y-11 " autoComplete="off">
                    <div className="field-group">
                        <input ref={headlineRef} type="text" name="headline" placeholder=" " className="input-fld"
                            onBlurCapture={() => {
                            textOnchangeSave("heading");
                            }}
                            {...register("heading",{
                                required: common.formValidation("Headline", "required"),
                            })}
                        />
                        <FormValidationIcon getval={getValues("heading")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"heading"}></FormValidationIcon>
                        <label htmlFor="heading" className="input-lbl">Headline</label>
                    </div>
                    <FormFieldError errors={errors}  fieldname={"headline"} ></FormFieldError>
                    <div className="field-group">
                        <input ref={aboutRef} type="text" name="about" placeholder=" " className="input-fld"
                            onBlurCapture={() => {
                            textOnchangeSave("about");
                            }}
                            {...register("about", {
                                required: common.formValidation("About", "required"),
                            })}
                        />
                        <FormValidationIcon getval={getValues("about")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"about"}></FormValidationIcon>
                        <label htmlFor="about" className="input-lbl">About</label>
                    </div>
                    <FormFieldError errors={errors}  fieldname={"about"} ></FormFieldError>
                    <div className="field-group">
                        <label className={(roleLabel || getValues('current_job_position ')) ? "input-lbl select-label" : "hidden"}>Current Job Position</label>
                        <Controller
                            name="current_job_position"
                            rules={{ required: 'Job Position is required' }}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    isClearable
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={role}
                                    getOptionLabel={e => e.sublabel ? e.label + ' - ' + e.sublabel : e.label}
                                    onChange={(e) => { handleRoleOnChange(e) }}
                                    onBlurName="onChange"
                                    onFocus={() => (setRoleLabel(true))}
                                    onBlur={() => { setRoleLabel(role ? true : false) }}
                                    ref={field.ref}
                                    classNamePrefix={"react-select"}
                                    placeholder={<label className={(!roleLabel && !getValues("current_job_position")) ? "input-lbl" : 'hidden'}>Current Job Position</label>}
                                    options={selectStore.role}
                                    formatOptionLabel={loc => (
                                        <div className='single-select-default flex'><span className="txt"> { loc.sublabel ? loc.label + ' - ' + loc.sublabel : loc.label}</span></div>
                                    )}
                                />
                            )}
                        />
                        <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>
                        <FormValidationIcon getval={getValues("current_job_position")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"current_job_position"}></FormValidationIcon>
                    </div>
                    <FormFieldError errors={errors} fieldname={"current_job_position"}></FormFieldError>
                    <div className="field-group">
                        <label className={locationLabel || getValues("location_id") ? "text-[14px] top-0 text-[#a3a3a3] -z-1 duration-300 origin-0 select-label" : "hidden" }>Location</label>
                        <Controller
                            name="location_id"
                            control={control}
                            rules={{ required: "Location is required" }}
                            render={({ field }) => (
                                <Select
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                isClearable
                                value={location}
                                onChange={(e) => { handleLocationOnchange(e); }}
                                onBlurName="onChange"
                                onFocus={() => setLocationLabel(true)}
                                onBlur={() => { setLocationLabel(location ? true : false) }}
                                formatOptionLabel={(loc) => (
                                    <div className="location-option single-select-default flex">
                                    <span className="loc-label">{loc.label}</span>
                                    </div>
                                )}
                                ref={field.ref}
                                classNamePrefix={"react-select"}
                                placeholder={
                                    <label className={ !locationLabel && !getValues("location_id")
                                        ? "text-[14px] top-0 text-[#a3a3a3] -z-1 duration-300 origin-0"
                                        : "hidden" }>Location
                                    </label>
                                }
                                options={selectStore.location}
                                />
                            )}
                        />
                        <FormSearchIcon getval={getValues("location_id")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"location_id"} ></FormSearchIcon>
                        <FormValidationIcon getval={getValues("location_id")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"location_id"}></FormValidationIcon>
                    </div>
                    <FormFieldError errors={errors} fieldname={"location_id"}></FormFieldError>
                    <div className="field-group">
                        <label className={(companyLabel || getValues('company_id')) ? "input-lbl select-label" : "hidden"}>Company</label>
                        <Controller
                            name="company_id"
                            control={control}
                            rules={{ required: 'Company is required' }}
                            render={(field) => (
                                <Select
                                    isClearable
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={company}
                                    onChange={(e) => { handleCompanyOnChange(e) }}
                                    onBlurName="onChange"
                                    onFocus={() => {setCompanyLabel(true);}}
                                    onBlur={() => { setCompanyLabel(company ? true : false) }}
                                    //onInputChange={handleInputChange}
                                    ref={field.ref}
                                    classNamePrefix={"react-select"}
                                    placeholder={<label className={(!companyLabel && !getValues("company_id")) ? "input-lbl" : 'hidden'}>Company</label>}
                                    options={selectStore.brands}
                                />
                            )} />
                            <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>
                            <FormValidationIcon getval={getValues("company_id")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"company_id"}></FormValidationIcon>
                    </div>
                    <FormFieldError errors={errors} fieldname={"company_id"}></FormFieldError>
                    <div className="field-group">
                        <label className={(industryLabel || getValues('industry_id')) ? "input-lbl select-label" : "hidden"}>Industry</label>
                            <Controller
                                name="industry_id"
                                rules={{ required: 'Industry is required' }}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        isClearable
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        value={industry}
                                        getOptionLabel={e => e.sublabel ? e.label + ' - ' + e.sublabel : e.label}
                                        onChange={(e) => { handleIndustryOnChange(e) }}
                                        onBlurName="onChange"
                                        onFocus={() => (setIndustryLabel(true))}
                                        onBlur={() => { setIndustryLabel(industry ? true : false) }}
                                        ref={field.ref}
                                        classNamePrefix={"react-select"}
                                        placeholder={<label className={(!industryLabel && !getValues("industry_id")) ? "input-lbl" : 'hidden'}>Industry</label>}
                                        options={selectStore.industry}
                                        formatOptionLabel={loc => (
                                            <div className='single-select-default flex'><span className="txt"> { loc.sublabel ? loc.label + ' - ' + loc.sublabel : loc.label}</span></div>
                                        )}
                                    />
                                )}
                            />
                        <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>
                        <FormValidationIcon getval={getValues("industry_id")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"industry_id"}></FormValidationIcon>
                    </div>
                    <FormFieldError errors={errors} fieldname={"industry_id"}></FormFieldError>
                </form>
            </ClientOnboardingTemplate>
            <FooterWithBackNext prevStep={props.prevStep} disabled={!isValid} validateForm={() => { trigger()}} submitHandler={() => submitHandler()} showBookCall={true} persentage="0%" text="1/7"></FooterWithBackNext>
        </div>
    )
}