import { createSlice } from '@reduxjs/toolkit'

export const common = createSlice({
  name: 'common_details',
  initialState: {
    platform: "",
    agency_id: "",
    talent_id: "",
    project_id: "",
    talent_profile_edit: false,
    agency_profile_edit: false,
    theme: localStorage.getItem("theme"),
    pageList: {},
    activeProfile: {
      id: "",
      platform: ""
    }
  },
  reducers: {
    insertValue: (state, action) => {
      state[action.payload.name] = action.payload.id;
      return state
    }
  },
})

// Action creators are generated for each case reducer function
export const { insertValue } = common.actions

export default common.reducer