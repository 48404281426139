import React from 'react';
import closeGrey from '../../../assets/icons/close-grey.svg';

export const ErrorAlertPopup = (props) => {
    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative w-[465px] h-[447px] m-auto transform overflow-hidden rounded-lg bg-white md:p-[32px] p-[10px] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="sm:items-start w-full flex">
                            <div className="m-auto text-center sm:text-left w-full">
                                <div className="sm:flex pb-[17px] border-b-[1px] border-grey-500 flex flex-row">
                                    <h3 className="text-[20px] font-bold leading-6 text-black-900" id="modal-title">Error Occured</h3>
                                    <div className="absolute right-0 xs:mt-[-10px] pr-7 sm:block mt-[4px]">
                                        <button type="button" onClick={props.close} className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]">
                                            <span className="sr-only">Close</span>
                                            <img src={closeGrey} alt="close" />
                                        </button>
                                    </div>
                                </div>
                                <div className='mt-[10px] flex flex-col'>
                                    <span>Type : {props.errorType}</span>
                                </div>
                                <div className='mt-[10px] flex flex-col'>
                                    <span>Message: {props.errorMessage}</span>
                                </div>
                                <div className='mt-[10px] flex flex-col'>
                                    <span>File: {props.fileName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
