import React, { useEffect } from 'react'
import { About } from './about';
import { Matchingprojects } from './matching-projects';

export const MatchingProfile = (props) => {
  return (
    <div>
      <div className='relative'>
        <About isEdit={props.isEdit} editDetails={props.editDetails} type={props.type} id={props.id} />
      </div>
      {/* <hr className='my-[88px]'></hr> */}
      <Matchingprojects name={props.name} editDetails={props.editDetails} type={props.type} id={props.id} />
    </div>
  )
}
