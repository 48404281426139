import React from 'react';
export const ClientOnboardingTemplate = (props) => {
    return (
        <div className="main-container">
            <div className='h-full [@media(min-width:1000px)]:grid [@media(max-width:1000px)]:place-items-center [@media(min-width:1000px)]:grid-cols-2 [@media(max-width:1000px)]:overflow-auto  flex flex-col w-full '>
                <div className='self-center left-dark [@media(min-width:1000px)]:max-h-full [@media(min-width:1000px)]:overflow-y-auto [@media(max-width:999px)]:text-center [@media(max-width:1000px)]:bg-[#f5f5f5]  text-white flex [@media(max-width:1000px)]:justify-center justify-end w-full'>
                    <div className='[@media(max-width:1335px)]:p-10 [@media(min-width:1335px)]:pr-[8.5rem] [@media(min-width:1335px)]:w-[623px] w-full align-right'>
                        {props.children[0]}
                    </div>
                </div>
                <div className='self-center right-dark max-h-full w-full  [@media(max-width:640px)]:mt-5 [@media(min-width:1000px)]:overflow-auto pt-5'>
                    <div className="
                    [@media(max-width:1335px)]:p-10 [@media(min-width:1335px)]:pl-[8.5rem] [@media(max-width:1335px)]:m-auto w-full [@media(min-width:1000px)]:w-[494px] [@media(max-width:1000px)]:mx-auto max-w-[445px] [@media(min-width:1000px)]:max-w-[496px]">
                        {props.children[1]}
                    </div>
                </div>
            </div>
        </div>
    )
}