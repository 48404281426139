import { createSlice } from '@reduxjs/toolkit'

export const client = createSlice({
  name: 'client_details',
  initialState: {
    page1: {
      email: "",
      receive_news: false,
      agree_to_terms: false,
    },
    page2: {
      headling: "",
      about: "",
      current_job_position: "",
      location_id: "",
      company_id: 0,
      industry_id: "",
    },
    page3: {
      skills: []
    },
    page4: {
      exeperience: [],
      year_of_experience: ""
    },
    page5: {
      tone_of_voice: []
    },
    page6: {
      brands: []
    },
    page7: {
      team_members: []
    },
    onboarding_progress: 0,
  },
  reducers: {
    insertValue: (state, action) => {
      state[action.payload.name] = action.payload.id;
      return state
    }
  },
})

// Action creators are generated for each case reducer function
export const { insertValue } = client.actions

export default client.reducer