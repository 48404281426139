import Rendertoast from "../../utils/rendertoast";
import { apiService } from "../../utils/service";
//Api to save/update project details
export const ProjectUpdate = (data) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "project/update",
            data: data,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                Rendertoast.showToast({ "type": "e", "msg": err ? err.message ? err.message : err : "Something went wrong" });
            })
    } catch (error) {

    }
}
//Api to create a new project by generating project id
export const AddNewProject = (data) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "project/new",
            data: data,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                Rendertoast.showToast({ "type": "e", "msg": err ? err.message ? err.message : err : "Unable to add project name" });
            })
    } catch (error) {

    }
}
//Api to delete the pre existing project
export const DeleteProject = (id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "project/delete",
            data: {
                "project_id": id
            },
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                Rendertoast.showToast({ "type": "e", "msg": err ? err.message ? err.message : err : "Unable to delete project" });
            })
    } catch (error) {

    }
}
//Api to get the saved data of a perticular project using project id
export const GetProjectResults = (dispatch, id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "project/results/generate",
            data: {
                "project_id": id
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    dispatch({ type: 'project-setup/insertValue', payload: { "id": res.data.results, "name": "results" } });
                }
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get the saved data of a perticular project using project id
export const GetProject = (dispatch, id, projectSetupStore) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "project/get",
            data: {
                "filter": {
                    "project_id": [
                        id
                    ]
                }
            }
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get the saved data of a perticular project using project id and save the data in store
export const GetProjectByID = (dispatch, id, projectSetupStore) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "project/get",
            data: {
                "filter": {
                    "project_id": [
                        id
                    ]
                }
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    Object.entries(projectSetupStore).forEach(key => {
                        var data = res.data.projects && res.data.projects.length && res.data.projects[0] ? res.data.projects[0] : {}
                        if (typeof key[1] !== "object" && data[key[0]]) {
                            dispatch({ type: 'project_setup/insertValue', payload: { "id": data[key[0]], "name": key[0] } });
                        } else if (typeof key[1] !== "object" && data[key[0]]) {
                            dispatch({ type: 'project_setup/insertValue', payload: { "id": data[key[0]], "name": key[0] } });
                        } else if (typeof key[1] !== "object" && !data[key[0]] && !data[key[0]]) {
                            dispatch({ type: 'project_setup/insertValue', payload: { "id": key[1], "name": key[0] } });
                        } else {
                            var val = objlooping(key, data, dispatch);
                            dispatch({ type: 'project_setup/insertValue', payload: val });
                        }
                    });
                }
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//looping object to save them in store
function objlooping(key, data, dispatch) {
    var val = {}
    Object.keys(key[1]).forEach(_key => {
        if (_key !== 'project_id') {
            if (data[_key]) {
                val[_key] = data[_key];
            } else if (data.talent && data.talent[_key]) {
                val[_key] = data.talent[_key];
            } else {
                val[_key] = key[1][_key]
            }
        }
    });
    return { "id": val, "name": key[0] }
}
//Api to generate the project estimation value
export const UpdateProjectEstimation = (id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "project/estimation/generate",
            data: {
                "project_id": id
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                }
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to generate the project demo request
export const ProjectDemoRequest = (data) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "demo/request",
            data: data
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get talent details using the talent id
export const GetTalentById = (id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "user/get/public",
            data: {
                "filter": {
                    "user_id": [id]
                }
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    return res.data;
                }
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get agency details using the agency id
export const GetAgencyById = (id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "agency/get/public",
            data: {
                "filter": {
                    "agency_id": [
                        id
                    ]
                }
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    return res.data;
                }
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get talent work
export const getTalentWork = (id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "work/get",
            data: {
                "filter": {
                    "talent_id": [
                        id
                    ]
                }
            }
        })
            .then((res) => {
                var worksList = [];
                var awardsList = []
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    const filteredItems = res.data.works.filter(item => Boolean(item.roles) && item.roles.length)
                    if (filteredItems && filteredItems.length) {
                        var awards = filteredItems.filter(item => 'awards' in item).map((awrds) => awrds.awards).flat();
                        var workWithAwd = filteredItems.filter(item => 'awards' in item).map((awrds) => awrds);
                        var modifiedObj = {}
                        workWithAwd.forEach((data, i) => {
                            data.awards.forEach(awd => {
                                modifiedObj[awd.award_id] = []
                            })
                        })
                        Object.entries(modifiedObj).forEach(ad => {
                            workWithAwd.forEach((data, i) => {
                                data.awards.forEach(awd => {
                                    if (ad[0] === awd.award_id) {
                                        ad[1].push(Object.assign({ client_id: data.client_id, work_id: data.work_id, company: data.company }, awd))
                                    }
                                })
                            })
                        })
                        worksList = filteredItems;
                        awardsList = awards;
                    } else {
                        worksList = [];
                        awardsList = [];
                    }
                    return { works: worksList, awards: awardsList }
                }
            })
            .catch((err) => {
                return ({ works: [], awards: [] })
            })
    } catch (error) {

    }
}
//Api to get agency work
export const getAgencyWork = (id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "work/get",
            data: {
                "filter": {
                    "agency_id": [
                        id
                    ]
                }
            }
        })
            .then((res) => {
                var worksList = [];
                var awardsList = []
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    const filteredItems = res.data.works.filter(item => Boolean(item.roles) && item.roles.length)
                    if (filteredItems && filteredItems.length) {
                        var awards = filteredItems.filter(item => 'awards' in item).map((awrds) => awrds.awards).flat();
                        var workWithAwd = filteredItems.filter(item => 'awards' in item).map((awrds) => awrds);
                        var modifiedObj = {}
                        workWithAwd.forEach((data, i) => {
                            data.awards.forEach(awd => {
                                modifiedObj[awd.award_id] = []
                            })
                        })
                        Object.entries(modifiedObj).forEach(ad => {
                            workWithAwd.forEach((data, i) => {
                                data.awards.forEach(awd => {
                                    if (ad[0] === awd.award_id) {
                                        ad[1].push(Object.assign({ client_id: data.client_id, work_id: data.work_id, company: data.company }, awd))
                                    }
                                })
                            })
                        })
                        worksList = filteredItems;
                        awardsList = awards;
                    } else {
                        worksList = [];
                        awardsList = [];
                    }
                    return { works: worksList, awards: awardsList }
                }
            })
            .catch((err) => {
                return ({ works: [], awards: [] })
            })
    } catch (error) {

    }
}
