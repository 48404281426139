import { createSlice } from '@reduxjs/toolkit'

export const agency = createSlice({
  name: 'agency_details',
  initialState: {
    page1: {
      email: "",
      linkedin_url: "",
      website_url: "",
      year_founded: "",
      company_size: "",
      name: "",
      location: "",
      profile_avatar_url: ""
    },
    page2: {
      services: [],
      outsource_services: []
    },
    page3: {
      diversity: {}
    },
    page4: {
      leaders: []
    },
    page5: {
      skills: []
    },
    page6: {
      industries: [],
      brands: []
    },
    page7: {
      weekly_rate: 0
    },
    platform_roles: "",
    user_id: "",
    onboarding_progress: 0,
    agency_id: "",
    agency_onboarding_completed_at: ""
  },
  reducers: {
    insertValue: (state, action) => {
      state[action.payload.name] = action.payload.id;
      return state
    }
  },
})

// Action creators are generated for each case reducer function
export const { insertValue } = agency.actions

export default agency.reducer