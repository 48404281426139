import React, { useEffect, useState } from "react"
import { ClientOnboardingTemplate } from "../common/client-onboarding-template"
import { FooterWithBackNext } from "../common/footer-with-back-next"
import { Header } from "../common/header"
import { Plus } from "../common/icons/plus"
import { Dot } from "../common/icons/dot";
import { Edit } from "../common/icons/edit";
import { Cancel } from "../common/icons/cancel";
import { TextContainer } from "../common/text-container"
import { TeamPopup } from "./team-popup"
import { useDispatch, useSelector } from "react-redux"

export const Team = (props) => {
    const head = "Who’s your <b class='text-[48px] font-bold'>crew</b>?";
    const subHead = "Bring your best collaborators onboard. The best and brightest that makes you shine. That way you can stack the deck for the next big assignment.";
    const clientStore = useSelector(state => state.clientStore);
    const [showPopup,setshowPopup] = useState(false)
    const [teamMember,setTeamMember] = useState([]);
    const [teamMemberEdit, setOpenTeamEdit] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        setTeamMember(clientStore.page7.team_members)
    }, [clientStore.page7]);
    
    /** SAVE TEAM VALUES ON POPUP */
    function saveValues(values, index) {
    var data = [...teamMember];
    if (values && (index || index === 0) && data.length) {
        data[index] = values;
        setTeamMember(data)
        onChangeData(data)
    } else {
        data = [...data, values];
        setTeamMember(data)
        onChangeData(data)
    }
    }
    
    /** EDIT TEAM MEMBER */
    function editHandler(data,index){
    setshowPopup(true)
    document.body.classList.add("overflow-y-hidden")
    setOpenTeamEdit({ data: data, index: index })
    }
    
    /** DELECT TEAM MEMBER */
    function deleteValue(index) {
    const data = [...teamMember];
    data.splice(index, 1);
    setTeamMember(data);
    onChangeData(data)
    }
    
    /**TEAM MEMBER ONCHANGE */
    function onChangeData(_teamMember){
    try{
        var teamMemberList = [];
        _teamMember.forEach((data) => {
        teamMemberList.push(data)
        })
        dispatch({ type: 'client_details/insertValue', payload: {"id":{team_members: teamMemberList},"name":"page7"} });
        //props.saveData({team_members: teamMemberList},true);
    }catch(err){console.log(err)}
    }
    
    /** SUBMIT TEAM MEMBER VALUES */
    function submitHandler(){
    try{
        var teamMemberList = [];
        teamMember.forEach((data) => {
        teamMemberList.push(data)
        })
      dispatch({ type: 'client_details/insertValue', payload: {"id":{team_members: teamMemberList},"name":"page7"} });
    //   props.nextStep({team_members: teamMemberList},true);
    }catch(err){console.log(err)}
    }
    
    /**SHOW TEAM MEMBER POPOUP SCREEN */
    function showpopupHandler(){
    setshowPopup(true);
    document.body.classList.add("overflow-y-hidden")
    setOpenTeamEdit({});
    }

    return(
        <div className="page-section">
            <Header isClient={true}></Header>
            <ClientOnboardingTemplate>
                <div className="role-textContainer">
                    <TextContainer head={head} subHead={subHead} activeTab="Team" activepersentage="86%" text="7/7"/>
                </div>
                <div className="team-container">
                    <form className="team" autoComplete="off">
                        <div className="field-group">
                            <input type="text" name="fullname" placeholder=" " className="input-fld !mb-0" disabled />
                            <label htmlFor="fullname" className="absolute archivo text-[14px] top-[-4px] text-[#a3a3a3] -z-1 duration-300 origin-0">Invite your team members</label>
                        </div>
                        <button onClick={showpopupHandler} type="button" className="hover:!bg-[#4C4C4C] dark:hover:!bg-[#F5F5F5]  mt-[16px] text-[14px] inline-flex bg-c-black dark:!bg-white dark:text-black text-white items-center rounded-full border border-transparent px-[16px] py-[8px] text-base font-bold shadow-sm focus:outline-none h-[36px]  [@media(max-width:640px)]:w-[100%] [@media(max-width:640px)]:justify-center">
                            Add team member<span className="ml-3"><Plus/></span>
                        </button>
                    </form>
                    <div className='mt-[24px]'>
                        <ul className="flex flex-col gap-[16px]">
                            {teamMember.map((data, j) => (
                            <React.Fragment key={j}>
                            <li className='border-[1px] min-h-[44px] archivo border-[#D9D9D9] dark:border-[#808080] dark:text-[#FFFFFF] dark:bg-[#1D1D1D] text-[#07091B] bg-[#FFFFFF] rounded-[6px] px-[16px] [@media(max-width:767px)]:p-[10px] justify-between text-[14px] font-medium flex'>
                            {/* <span className='font-medium flex items-center w-[20px] h-[20px] [@media(max-width:767px)]:h-[16.14px] [@media(max-width:767px)]:w-[16.14px] my-auto'>
                                <span className="inline-flex items-center justify-center w-[85%] h-[auto] text-[11px] font-semibold text-white bg-[#07091b] dark:bg-white dark:text-[#0E0E0E] rounded-full">{j +1}</span>
                            </span> */}
                                <span className="font-medium archivo block items-center break-normal w-[30%] whitespace-nowrap overflow-hidden text-ellipsis m-auto">{data['name']}</span>
                                {data['email'] !== "" && <span className="m-[10px] w-[1%] flex items-center"><Dot/></span>}
                                <span className='font-medium archivo block items-center break-normal whitespace-nowrap overflow-hidden text-ellipsis w-[35%] m-auto'>{data['email']}</span>
                                <div className='float-right flex'>
                                <button onClick={(e) => editHandler(data,j)} className="flex items-center m-auto !bg-transparent">
                                <span className="m-[8px] [@media(max-width:767px)]:m-[4px]"><Edit/></span>
                                </button>
                                <button onClick={(e) => deleteValue(j)} className="flex items-center m-auto !bg-transparent">
                                <span className="m-[8px] [@media(max-width:767px)]:m-[4px]"><Cancel/></span>
                                </button>
                            </div>
                                </li>
                            </React.Fragment>
                            ))}
                        </ul>
                    </div>
                </div>
            </ClientOnboardingTemplate>
            <FooterWithBackNext prevStep={props.prevStep} showSkip={true} skipStep={props.skipStep} lastPage={props.lastPage} showDone={true} submitHandler={submitHandler} persentage="71.4%" text="7/7"></FooterWithBackNext>
            {showPopup && <TeamPopup close={()=> {setshowPopup(false);document.body.classList.remove("overflow-y-hidden")}} save={saveValues} value={teamMemberEdit}></TeamPopup>} 
        </div>
    )
}