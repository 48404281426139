import { createSlice } from '@reduxjs/toolkit'

export const talent = createSlice({
  name: 'talent_details',
  initialState: {
    page1: {
      email: "",
      first_name: "",
      last_name: "",
      linkedin_profile_url: "",
      portfolio_url: "",
      location_id: "",
      serving_markets: [],
      profile_avatar_url: ""
    },
    page2: {
      pronouns: {},
      cultural_identity: {},
    },
    page3: {
      discipline_id: "",
      roles: [],
      level_id: ""
    },
    page4: {
      skills: [],
      leadership_skill: ""
    },
    page5: {
      industries: [],
      brands: []
    },
    page6: {
      exeperience: [],
      career_start_year: ""
    },
    page7: {
      daily_rate: 0
    },
    page8: {
      team_members: []
    },
    page9: {
      tone_of_voice: []
    },
    page10: {
      website_url: "",
      instagram: "",
      twitter: "",
      tiktok: "",
      youtube_channel: "",
      vimeo_username: ""
    },
    page11: {
      invite: ""
    },
    page12: {
      network: []
    },
    user_id: "",
    onboarding_progress: 0,
    talent_onboarding_completed_at: ""
  },
  reducers: {
    insertValue: (state, action) => {
      state[action.payload.name] = action.payload.id;
      return state
    }
  },
})

// Action creators are generated for each case reducer function
export const { insertValue } = talent.actions

export default talent.reducer