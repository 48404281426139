import { createSlice } from '@reduxjs/toolkit'

export const select = createSlice({
    name: 'drop_down_option',
    initialState: {
      location:[],
      market:[],
      pronouns:[],
      culturalIdentity:[],
      discipline:[],
      role:[],
      level:[],
      skils:[],
      leadershipSkils:[],
      industry:[],
      brand:[],
      awards:[],
      tone:[],
      companysize:[],
      diversity:[],
      yearstarted:[],
      brands:[],
      project_category:[],
      yearstartedcareer:[]
    },
    reducers: {
      insertValue: (state, action) => {
        state[action.payload.name] = action.payload.value;
        return state
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { insertValue } = select.actions
  
  export default select.reducer