import React, { useEffect } from 'react';
import nextArrowWhite from '../../../assets/img/next-arrow-white.svg';
import { Header } from '../common/header';

function Signup(props) {

    useEffect(() => {
        if(document.body.classList.contains('dark')) {
            document.body.classList.remove('dark');
        }
    }, [])
    return (
        <div className="page-section authentication">
            <Header isLogin={true}></Header>
            <div className="main-container flex flex-1 overflow-hidden w-full mx-auto">
                <div className='h-full sm:grid [@media(max-width:640px)]:place-items-center [@media(min-width:640px)]:grid-cols-2 [@media(max-width:640px)]:overflow-auto  flex flex-col w-full'>
                    <div className='[@media(min-width:640px)]:items-end [@media(max-width:640px)]:max-h-[400px]  [@media(max-width:640px)]:self-baseline [@media(max-width:640px)]:p-[24px] p-10 w-full [@media(max-width:640px)]:text-center h-full flex flex-col [@media(max-width:640px)]:bg-c-black text-white justify-center'>
                         <div className='[@media(max-width:1250px)]:p-10 [@media(min-width:1250px)]:pr-36 lg:w-[630px] align-right'>
                             <h1 className="form-head">Sign Up</h1>
                             <p className='text-[18px] mt-[21px] text-[#d9d9d9] [@media(max-width:640px)]:w-full font-normal lg:w-[480px]'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                         </div>
                    </div>
                    <div className='self-center [@media(max-width:640px)]:mt-[50px] w-full max-h-full [@media(min-width:640px)]:overflow-auto sm:w-[70%] lg:w-[60%] [@media(min-width:640px)]:ml-[9%]'>
                        <form className="overflow-hidden [@media(max-width:640px)]:p-[24px] p-10 space-y-8 sm:space-y-10 w-full pb-0" autoComplete="off">
                            <div className="field-group w-full">
                                <input type="text" name="email" placeholder=" " className="input-fld" />
                                <label htmlFor="email" className="input-lbl">Email</label>
                            </div>
                            <div className="field-group w-full">
                                <input type="password" name="password" placeholder=" " className="input-fld" />
                                <label htmlFor="password" className="input-lbl">Passoword</label>
                            </div>
                            <div className="field-group w-full">
                                <input type="password" name="confirmpassword" placeholder=" " className="input-fld" />
                                <label htmlFor="confirmpassword" className="input-lbl">Confirm Passoword</label>
                            </div>
                        </form>
                        <div className='overflow-hidden [@media(max-width:640px)]:mb-[60px] [@media(max-width:640px)]:px-[24px] p-10 space-y-7 sm:space-y-7 pt-0 '>
                        <div className="relative !mt-[18px] flex flex-col gap-4 w-full">
                                <div className='text-left'><input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/><label htmlFor="vehicle1" className='p-[14px] text-color-4C4C4C'>Yes, i agree to recive carrusela news and update.</label></div>
                                <div className='text-left'><input type="checkbox" id="vehicle1" name="vehicle1" checked value="Bike"/><label htmlFor="vehicle1" className='p-[14px] text-color-4C4C4C font-normal'>Yes, i agree to the terms and privacy policy.</label></div>
                            </div>
                            <div className='w-full'>
                                <button type="button" className=" w-full text-center flex items-center rounded-full bg-[#121212] text-white text-[16px] px-8 py-3  font-bold shadow-sm h-[48px] mr-4 justify-center">Create account<img src={nextArrowWhite} alt="Next Arrow" className='p-[10px]'/></button>
                            </div>
                            <div className='w-full text-left'>
                                <p className='text-[16px] font-normal text-color-4C4C4C'>Already have an account? please <a href="/login"><b className='text-[16px] font-bold'>Log in.</b></a></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Signup;