import React from 'react';
import CryptoJS from "crypto-js";
class Common extends React.Component {

    constructor(props) {
        super(props);
        this.getDifference = this.getDifference.bind(this);
    }
    osBrowserDetails() {
        let nAgt = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = '' + parseFloat(navigator.appVersion);
        let majorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset, verOffset, ix;

        let osName = "Unknown OS";
        if (navigator.userAgent.indexOf("Win") !== -1) osName =
            "Windows OS";
        if (navigator.userAgent.indexOf("Mac") !== -1) osName =
            "Macintosh";
        if (navigator.userAgent.indexOf("Linux") !== -1) osName =
            "Linux OS";
        if (navigator.userAgent.indexOf("Android") !== -1) osName =
            "Android OS";
        if (navigator.userAgent.indexOf("like Mac") !== -1) osName =
            "iOS";

        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgentelse 
        if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) !== -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) !== -1)
            fullVersion = fullVersion.substring(0, ix);

        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
        return {
            browsername: browserName,
            osName: osName,
            fullversion: fullVersion,
            majorversion: majorVersion,
            navigatorappname: navigator.appName,
            navigatoruseragent: navigator.userAgent
        }
    }

    formValidation(fieldname, type, min, max) {
        switch (type) {
            case 'required':
                return `${fieldname} is required`
            case 'minLength':
                return `${fieldname} should have atleast ${min} characters`
            case 'maxLength':
                return `${fieldname} should not exceed ${max} characters`
            case 'pattern':
                return `Enter a valid ${fieldname}`
            case 'minValue':
                return `${fieldname} should have atleast ${min} value`
            default:
                break;
        }
    }
    getDifference(o1, o2) {
        var diff = {};
        var tmp = null;
        var self = this;
        if (JSON.stringify(o1) === JSON.stringify(o2)) return;

        for (const k in o1) {
            if (Array.isArray(o1[k]) && Array.isArray(o2[k])) {
                tmp = o1[k].reduce(function (p, c, i) {
                    var _t = self.getDifference(c, o2[k][i]);
                    if (_t)
                        p.push(_t);
                    return p;
                }, []);
                if (Object.keys(tmp).length > 0)
                    diff[k] = tmp;
            } else if (typeof (o1[k]) === "object" && typeof (o2[k]) === "object") {
                tmp = self.getDifference(o1[k], o2[k]);
                if (tmp && Object.keys(tmp) > 0)
                    diff[k] = tmp;
            } else if (o1[k] !== o2[k]) {
                diff[k] = o2[k]
            }
        }
        return diff;
    }
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    dateConverter(date) {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const hours = String(inputDate.getHours()).padStart(2, '0');
        const minutes = String(inputDate.getMinutes()).padStart(2, '0');
        const seconds = String(inputDate.getSeconds()).padStart(2, '0');

        const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
        return isoDateString;
    }
    encryptdecrypt(type, text) {
        const secretPass = "XkhZG4fW2t2W";
        if (type === "encrypt") {
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(text), secretPass).toString();
            var _ciphertext = ciphertext.replaceAll('/', 'isslash');
            return _ciphertext;
        } else {
            var _text = text.toString().replaceAll('isslash', '/');
            const bytes = CryptoJS.AES.decrypt(_text, secretPass);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }

    }
    /**SET THEME */
    setTheme(theme) {
        if (!document.body.classList.contains(theme) || !document.body.classList.length) {
            if (theme === 'light') {
                if (document.body.classList.contains('dark')) {
                    document.body.classList.remove('dark')
                }
                document.body.classList.add(theme);
            } else if (theme === 'dark') {
                if (document.body.classList.contains('light')) {
                    document.body.classList.remove('light');
                }
                document.body.classList.add(theme);
            }
        } else {
            document.body.classList.add(theme);
        }
    }

    checkIsMedia(url, extensions) {
        try {
            if (Boolean(new URL(url)) && url !== "") {
                var url1 = new URL(url)
                if (url1 && url1.pathname) {
                    var imagExtension = url1.pathname.split(".")[1];
                    return extensions.includes(imagExtension) ? true : false;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
        catch (e) {
            return false;
        }
    }

    visibleAllParent(cls) {
        var allOtherParent = document.querySelectorAll(`${cls}.demoFixed .react-select__option:has(.parent)`);
        if (allOtherParent) {
            [...allOtherParent].forEach(div => {
                if (div.style.display === 'none') {
                    div.style.display = 'block'
                }
            });
        }
    }

    onlyActiveElementVisible(cls) {
        var allOtherParent = document.querySelectorAll(`${cls}.demoFixed .react-select__option:has(.parent:not(.active))`);
        if (allOtherParent) {
            [...allOtherParent].forEach(div => {
                if (div.style.display !== 'none') {
                    div.style.display = 'none'
                }
            });
        }
    }

    focusHandler() {
        document.body.classList.add("overall-overflow-inherit")
    }

    focusoutHandler() {
        document.body.classList.remove("overall-overflow-inherit")
    }

    getTalentName(talent, userDetails) {
        var tal1, tal2;
        if (talent && talent.length) {
            talent.forEach((val, index) => {
                var talIndex = userDetails.findIndex((user) => user.user_id === val.id);
                if (talIndex !== -1) {
                    var userData = userDetails[talIndex];
                    if (index === 0) {
                        tal1 = userData.first_name ? userData.first_name : talent[index].name ? talent[index].name : 'Name'
                    } else if (index === 1) {
                        tal2 = userData.first_name ? userData.first_name : talent[index].name ? talent[index].name : 'Name'
                    } else {
                        tal1 = 'Name';
                        tal2 = 'Name'
                    }
                }
            })
            return tal1 + ' + ' + tal2
        }
    }
}
const common = new Common();
export default common;