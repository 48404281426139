import logo from "../../../assets/img/initial-logo.svg";
import darklogo from "../../../assets/img/dark-initial-logo.svg";
import button from "../../../assets/img/initial-btn-2.svg";
import darkbutton from "../../../assets/img/dark-initial-btn-2.svg";
import buttonSym from "../../../assets/img/initial-btn-sym.svg";
import background from "../../../assets/img/Isolation_Mode.svg";
import mlogo from "../../../assets/img/m-logo-light.png";
import darkmlogo from "../../../assets/img/m-logo-dark.png";
import { useNavigate } from "react-router-dom";
import { AddNewProject, UpdateProjectEstimation } from "../../service/project-setup-api";
import { useDispatch, useSelector } from "react-redux";
import common from "../../../utils/common";
import { useEffect } from "react";
export const InitialPage = (props) => {
    const commonStore = useSelector(state => state.commonStore);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        if (commonStore.theme) {
            if (commonStore.theme === 'light') {
                document.body.classList.remove('dark')
            } else {
                document.body.classList.add(commonStore.theme);
            }
        }
    }, [commonStore.theme])

    function createNewProject() {
        var dummayProjectName = Date.now() + ((Math.random() * 100000).toFixed());
        var req = { input: { name: dummayProjectName } }
        AddNewProject(req).then((res) => {
            if (res && res.status === 200 && res.data && res.data.project_id) {
                dispatch({ type: 'project_setup/insertValue', payload: { "id": res.data.project_id, "name": "project_id" } });
                /**CALL PROJECT ESTIMATION API TO GET DUMMY VALUE */
                UpdateProjectEstimation(res.data.project_id);
                navigate(`./project-setup?${common.encryptdecrypt("encrypt", res.data.project_id)}`)
            }
        }
        )
    }

    return (
        <div className="main-container project_initial">
            <div className="setup-container w-full overflow-auto mobile:overflow-hidden tablet:overflow-hidden min-h-[720px]">

                <div className="max-w-[1920px] w-full mx-auto h-full relative bg-white dark:bg-[#1D1D1D] flex-row flex mobile:flex-col tablet:flex-col tablet:justify-between mobile:gap-[19px]">

                    <div className="w-[46%] [@media(min-width:1920px)]:w-[676px] tablet:w-full h-full p-[88px] mobile:py-[64px] mobile:w-full mobile:px-[24px] mobile:h-auto">

                        <div className="relative w-full h-full flex items-center mobile:h-auto mobile:items-start tablet:h-auto tablet:items-start flex-wrap mobile:gap-[48px] tablet:gap-[48px]">
                            <div className="w-full absolute top-0 left-0 mobile:relative mobile:top-auto mobile:left-auto mobile:float-left mobile:flex mobile:justify-center tablet:relative tablet:top-auto tablet:left-auto tablet:float-left tablet:flex tablet:justify-center">

                                <img className="dark:hidden mobile:max-w-[138px]" alt="logo" src={logo} />
                                <img className="hidden dark:block mobile:max-w-[138px]" alt="logo" src={darklogo} />

                            </div>

                            <div className="flex-col justify-center items-start gap-12 flex self-center w-full">
                                <div className="flex-col justify-center items-start gap-6 w-[500px] flex mobile:gap-[24px] tablet:gap[24px] tablet:w-full mobile:w-full">
                                    <div className="w-full text-stone-950 text-[63px] font-semibold leading-[91.5%] tracking-[-1.89px] mobile:text-[48px] mobile:text-center tablet:text-[48px] tablet:text-center dark:text-[#fff]">Weaponize creativity</div>
                                    <div className="w-full text-neutral-800 text-[20px] font-normal leading-[130%] tablet:text-[16px] tablet:text-center mobile:text-[16px] mobile:text-center dark:text-[#A3A3A3]">This is mission control. The place where we get all the right inputs to so we can align the creative superstars to crush up your business problem. </div>
                                </div>
                                <div className="w-full relative mobile:flex mobile:justify-center tablet:justify-center tablet:flex">
                                    <button className="w-[229.3px] h-[76.02px] " onClick={createNewProject} >
                                        <div className="w-full h-full justify-center items-center gap-[7.88px] flex">
                                            <div className="absolute top-[0] left-[0] mobile:left-[calc(50%-115px)] tablet:left-[calc(50%-115px)]"><img alt="icon-bg" src={button} className="dark:hidden" /><img alt="icon-bg" src={darkbutton} className="hidden dark:block" /></div>
                                            <div className="w-[9.14px] h-[22.05px] relative">
                                                <img alt="icon" src={buttonSym} />
                                            </div>
                                            <div className="text-center text-black text-[16.5px] font-bold dark:text-[#fff]">Launch now</div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="w-[54%] [@media(min-width:1920px)]:flex-grow h-full mobile:w-full mobile:h-auto tablet:w-full tablet:h-auto" >

                        <div className="relative float-left w-full h-full  bg-cover  flex items-center mobile:h-[400px] mobile:items-start tablet:h-[400px] tablet:items-start !dark:bg-none" style = {{ background: `url(${commonStore.theme === 'light' ? background : ''}) no-repeat top right`}} >
                            <div className="pl-[10%] pr-[22%] mobile:px-[20%] tablet:px-[30%] mobile:m-auto tablet:m-auto">
                                <span>
                                    <img alt="mlogo" src={mlogo} className="dark:hidden block mobile:dark:hidden tablet:dark:hidden"/>
                                    <img alt="mlogo" src={darkmlogo} className="dark:block hidden mobile:dark:block tablet:dark:block"/>
                                </span>
                                
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}