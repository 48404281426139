import React from 'react'

export const MatchingRating = (props) => {
  return (
    <div>
    <div className='flex flex-col gap-[32px]'>
        <div id="profile-svg" className="-mt-[25px] w-[200px] h-[200px] [@media(max-width:1023px)]:m-auto">
        {props.data.photo_url && props.data.photo_url !== "" && <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 48 48" fill="none" className={`no-image prof-img`}>
                                                                <path d="M30.3842 0.0314609C34.3003 0.0139939 37.4377 0 38.8378 0C44.4244 0 48 3.33891 48 9.34739V14.49V42.8574V48H11.6199C5.78698 48 5.14437 42.7999 5.10795 42.4544C4.68128 42.3862 0 41.545 0 37.3269V32.1843V5.14261V0C0 0.166984 18.6736 0.0836935 30.3842 0.0314609Z" fill={`url(#pattern${props.data.photo_url})`}></path>
                                                                <defs>
                                                                    <pattern id={`pattern${props.data.photo_url}`} patternContentUnits="objectBoundingBox" width="1" height="1">
                                                                        <use xlinkHref={`#${props.data.photo_url}`} transform="scale(0.0005)"></use>
                                                                    </pattern>
                                                                    <image id={props.data.photo_url} width="2000" height="2000" xlinkHref={props.data.photo_url} preserveAspectRatio="xMidYMid slice"></image>
                                                                </defs>
                                                            </svg>}
                                                            {props.data.photo_url && props.data.photo_url && props.data.photo_url === "" || !props.data.photo_url && <svg width="237" height="212" viewBox="0 0 237 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g id="Group 8098">
                                                            <rect id="Rectangle 3107" x="29.6797" y="32.5879" width="180.056" height="142.019" fill="#FBFBFB"/>
                                                            <path id="Vector" d="M191.767 0.544922C164.25 0.544922 0.474609 1.64242 0.474609 0.544922C0.474609 0.544922 0.474609 140.429 0.474609 159.065C0.474609 179.841 23.5318 183.984 25.6334 184.32C25.8128 186.022 28.9779 211.634 57.7076 211.634C72.0169 211.634 236.894 211.634 236.894 211.634C236.894 211.634 236.894 72.4181 236.894 46.5844C236.894 16.9903 219.283 0.544922 191.767 0.544922ZM54.3161 146.265H38.405C38.405 146.265 38.405 91.8454 38.405 82.891C38.405 64.2932 54.3161 64.2932 54.3161 64.2932V146.269V146.265ZM185.359 146.265V64.2932H114.031C115.047 64.3145 129.886 64.9313 129.886 82.891V146.265H113.975V64.2932H54.4997C54.4997 64.2932 54.4997 48.4476 73.1745 48.4476H94.1856C108.328 48.4476 111.758 57.5296 112.596 61.9408C113.428 57.5296 116.863 48.4476 131.005 48.4476H167.061C185.735 48.4476 185.735 64.2932 185.735 64.2932H185.415C186.432 64.3145 201.266 64.9313 201.266 82.891C201.266 91.8454 201.266 146.265 201.266 146.265H185.355H185.359Z" fill="#A3A3A3"/>
                                                            </g>
                                                            </svg>}
            
        </div>
<div className='flex flex-col justify-center gap-[16px]'>
<p className='text-[64px] tracking-[-2.64px] font-medium leading-[100%] capitalize h-[64px] overflow-hidden text-[#0E0E0E]'>{props.name}</p>
<div className='text-[18px] text-[#4c4c4c]'>Founder & co-CCO</div>
<p className='text-[20px] tracking-[-0.72px] leading-[100%] font-normal text-[#0E0E0E]'>Rob lorem Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
</div>
</div>
<div className='grid w-full mt-[88px] mb-[24px]'>
<div>
<span className='text-[19.942px] text-[#0E0E0E] leading-[100%] tracking-[-0.598px] capitalize'>Match Rating</span>
<div className='flex gap-[5px] items-center mt-[10px]'>
    <div className="content w-full">
        <section className='loading flex flex-row gap-[16px] w-full !p-0'>    
            <div className="progress !mb-[0px]">
                <span className="progress-bar cool" style={{width:`${(Math.round(props.data.match_rate * 100))}%`}}></span>
            </div>
            <div className='font-bold text-[#0E0E0E] tracking-[-1.44px] leading-[100%] text-[48px] mt-[-15px]'>{(Math.round(props.data.match_rate * 100))}%</div> 
        </section>
    </div>
</div>
<div className='grid row-[2/3] [@media(max-width:1023px)]:gap-[16px] gap-[32px] [@media(max-width:1023px)]:grid-cols-1 pt-[48px] grid-cols-2'>
    <div>
        <span className='text-[18px] leading-[100%] tracking-[-0.54px] text-[#0E0E0E] capitalize'>Skills and Expertise</span>
        <div className='flex gap-[5px] items-center mt-[10px]'>
            <div className="content w-full">
                <section className='loading flex flex-row gap-[16px] w-full'>    
                    <div className="progress !h-[10px] !mb-[0px]">
                        <span className="progress-bar cool" style={{width:`${(Math.round(props.data.match_rate_skills * 100))}%`}}></span>
                    </div>
                    <div className='font-normal text-[#0E0E0E] tracking-[-0.96px] leading-[100%] text-[32px] mt-[-11px]'>{(Math.round(props.data.match_rate_skills * 100))}%</div> 
                </section>
            </div>
        </div>
    </div> 
    <div>
        <span className='text-[18px] leading-[100%] tracking-[-0.54px] text-[#0E0E0E] capitalize'>Diversity</span>
        <div className='flex gap-[5px] items-center mt-[10px]'>
            <div className="content w-full">
                <section className='loading flex flex-row gap-[16px] w-full'>    
                    <div className="progress !h-[10px] !mb-[0px]">
                        <span className="progress-bar cool" style={{width:`${(Math.round(props.data.match_rate_diversity * 100))}%`}}></span>
                    </div>
                    <div className='font-normal text-[#0E0E0E] tracking-[-0.96px] leading-[100%] text-[32px] mt-[-11px]'>{(Math.round(props.data.match_rate_diversity * 100))}%</div> 
                </section>
            </div>
        </div>
    </div> 
    <div>
        <span className='text-[18px] leading-[100%] tracking-[-0.54px] text-[#0E0E0E] capitalize'>Industry</span>
        <div className='flex gap-[5px] items-center mt-[10px]'>
            <div className="content w-full">
                <section className='loading flex flex-row gap-[16px] w-full'>    
                    <div className="progress !h-[10px] !mb-[0px]">
                        <span className="progress-bar cool" style={{width:`${(Math.round(props.data.match_rate_industry * 100))}%`}}></span>
                    </div>
                    <div className='font-normal text-[#0E0E0E] tracking-[-0.96px] leading-[100%] text-[32px] mt-[-11px]'>{(Math.round(props.data.match_rate_industry * 100))}%</div> 
                </section>
            </div>
        </div>
    </div> 
    <div>
        <span className='text-[18px] leading-[100%] tracking-[-0.54px] text-[#0E0E0E] capitalize'>Brand Voice</span>
        <div className='flex gap-[5px] items-center mt-[10px]'>
            <div className="content w-full">
                <section className='loading flex flex-row gap-[16px] w-full'>    
                    <div className="progress !h-[10px] !mb-[0px]">
                        <span className="progress-bar cool" style={{width:`${(Math.round(props.data.match_rate_voice * 100))}%`}}></span>
                    </div>
                    <div className='font-normal text-[#0E0E0E] tracking-[-0.96px] leading-[100%] text-[32px] mt-[-11px]'>{(Math.round(props.data.match_rate_voice * 100))}%</div> 
                </section>
            </div>
        </div>
    </div> 
    <div>
        <span className='text-[18px] leading-[100%] tracking-[-0.54px] text-[#0E0E0E] capitalize'>Target Market</span>
        <div className='flex gap-[5px] items-center mt-[10px]'>
            <div className="content w-full">
                <section className='loading flex flex-row gap-[16px] w-full'>    
                    <div className="progress !h-[10px] !mb-[0px]">
                        <span className="progress-bar cool" style={{width:`${(Math.round(props.data.match_rate_market * 100))}%`}}></span>
                    </div>
                    <div className='font-normal text-[#0E0E0E] tracking-[-0.96px] leading-[100%] text-[32px] mt-[-11px]'>{(Math.round(props.data.match_rate_market * 100))}%</div> 
                </section>
            </div>
        </div>
    </div> 
    </div>
</div>
</div>
{/* <button className='border-[1px] text-[16px] border-[#1d1d1d] px-[24px] font-bold rounded-full py-[12px]'>View more</button> */}
</div>
  )
}
