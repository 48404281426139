import { configureStore } from '@reduxjs/toolkit';
import talentReducer from './talent-reducer';
import selectReducer from './select-reducer';
import agencyReducer from "./agency-reducer"
import commonReducer from './common-reducer';
import talentProfileReducer from './talentProfile-reducer';
import agencyProfileReducer from './agencyProfile-reducer';
import projectSetupReducer from './projectSetup-reducer';
import clientReducer from './client-reducer';

const reducer = {
  talentStore: talentReducer,
  selectStore: selectReducer,
  agencyStore: agencyReducer,
  commonStore: commonReducer,
  clientStore: clientReducer,
  talentProfileStore : talentProfileReducer,
  agencyProfileStore : agencyProfileReducer,
  projectSetupStore : projectSetupReducer
}
export const store = configureStore({
  reducer
}); 