import React from 'react'

export const Tick = () => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='tick-icon dark:hidden' >
                <path d="M7.99651 15.5C12.1179 15.5 15.5 12.1248 15.5 8.00349C15.5 3.88215 12.1179 0.5 7.99651 0.5C3.87517 0.5 0.5 3.88215 0.5 8.00349C0.5 12.1248 3.87517 15.5 7.99651 15.5ZM7.2364 11.546C6.88773 11.546 6.60879 11.3856 6.37169 11.0997L4.79568 9.24477C4.60042 9.02162 4.53766 8.84031 4.53766 8.61018C4.53766 8.11506 4.94212 7.73152 5.42329 7.73152C5.70223 7.73152 5.91841 7.8431 6.11367 8.07322L7.22245 9.39121L9.80265 5.31869C10.0188 4.98396 10.2629 4.80962 10.5837 4.80962C11.0649 4.80962 11.4972 5.16527 11.4972 5.65342C11.4972 5.8417 11.4344 6.05091 11.295 6.24616L8.07322 11.0788C7.87796 11.3717 7.57113 11.546 7.2364 11.546Z" fill="#1D1D1D" />
            </svg>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='tick-icon hidden dark:block' >
                <path d="M7.99651 15.6011C12.1179 15.6011 15.5 12.2259 15.5 8.10456C15.5 3.98322 12.1179 0.601074 7.99651 0.601074C3.87517 0.601074 0.5 3.98322 0.5 8.10456C0.5 12.2259 3.87517 15.6011 7.99651 15.6011ZM7.2364 11.6471C6.88773 11.6471 6.60879 11.4867 6.37169 11.2008L4.79568 9.34584C4.60042 9.12269 4.53766 8.94138 4.53766 8.71126C4.53766 8.21614 4.94212 7.83259 5.42329 7.83259C5.70223 7.83259 5.91841 7.94417 6.11367 8.1743L7.22245 9.49229L9.80265 5.41976C10.0188 5.08503 10.2629 4.9107 10.5837 4.9107C11.0649 4.9107 11.4972 5.26635 11.4972 5.75449C11.4972 5.94278 11.4344 6.15198 11.295 6.34724L8.07322 11.1799C7.87796 11.4728 7.57113 11.6471 7.2364 11.6471Z" fill="white" />
            </svg>
        </>
    )
}
