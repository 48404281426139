import React from 'react'

export const Plus = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='plus-icon'>
      <rect x="5.14282" width="1.71429" height="12" fill="black" />
      <rect y="5.14286" width="12" height="1.71429" fill="black" />
    </svg>
  )
}
