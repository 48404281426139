import React from 'react';


export const ProfileImage = (props) => {
    return (
        <>
            {props && props.url && <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 48 48" fill="none" className={`no-image prof-img`}>
                <path d="M30.3842 0.0314609C34.3003 0.0139939 37.4377 0 38.8378 0C44.4244 0 48 3.33891 48 9.34739V14.49V42.8574V48H11.6199C5.78698 48 5.14437 42.7999 5.10795 42.4544C4.68128 42.3862 0 41.545 0 37.3269V32.1843V5.14261V0C0 0.166984 18.6736 0.0836935 30.3842 0.0314609Z" fill={`url(#pattern${props.url})`}></path>
                <defs>
                    <pattern id={`pattern${props.url}`} patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref={`#${props.url}`} transform="scale(0.0005)"></use>
                    </pattern>
                    <image id={props.url} width="2000" height="2000" xlinkHref={props.url} preserveAspectRatio="xMidYMid slice"></image>
                </defs>
            </svg>}
            {(!props || !props.url || props.url === "") && <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 56 50" fill="none" className={`prof-img`}>
            <path d="M45.3107 0C38.793 0 0 0.25996 0 0C0 0 0 33.1338 0 37.5481C0 42.4692 5.46149 43.4506 5.95927 43.5302C6.00177 43.9333 6.75148 50 13.5566 50C16.946 50 56 50 56 50C56 50 56 17.0244 56 10.9052C56 3.89537 51.8285 0 45.3107 0Z" fill={"url(#pattern0)"}></path>
                <g clipPath="url(#clip0_4421_34877)" className='rect'>
                    <path d="M45.3107 0C38.793 0 0 0.25996 0 0C0 0 0 33.1338 0 37.5481C0 42.4692 5.46149 43.4506 5.95927 43.5302C6.00177 43.9333 6.75148 50 13.5566 50C16.946 50 56 50 56 50C56 50 56 17.0244 56 10.9052C56 3.89537 51.8285 0 45.3107 0Z" fill="#D9D9D9" />
                    <g clipPath="url(#clip1_4421_34877)" className='user-avatar'>
                        <path d="M19.6816 21.2292C19.6816 25.8235 23.4059 29.5479 28.0002 29.5479C32.5944 29.5479 36.3188 25.8235 36.3188 21.2292C36.3188 16.635 32.5944 12.9106 28.0002 12.9106C23.4059 12.9106 19.6816 16.635 19.6816 21.2292Z" fill="#333333" />
                        <path d="M42.5577 50.3444C42.5577 42.3044 36.0401 35.7868 28.0002 35.7868C19.9603 35.7868 13.4426 42.3044 13.4426 50.3444M28.0002 29.5479C23.4059 29.5479 19.6816 25.8235 19.6816 21.2292C19.6816 16.635 23.4059 12.9106 28.0002 12.9106C32.5944 12.9106 36.3188 16.635 36.3188 21.2292C36.3188 25.8235 32.5944 29.5479 28.0002 29.5479Z" stroke="#333333" strokeWidth="4.1593" strokeLinecap="round" strokeLinejoin="round" />
                        <circle cx="28" cy="51.8935" r="16.1001" fill="#333333" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_4421_34877">
                        <rect width="56" height="50" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_4421_34877">
                        <rect width="49.9116" height="49.9116" fill="white" transform="translate(3.04419 6.67188)" />
                    </clipPath>
                </defs>
            </svg>} 

        </>
    )
}