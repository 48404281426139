import Rendertoast from "../../utils/rendertoast";
import { apiService } from "../../utils/service";
//Api to update/save the client onboarding form details
export const FormDataUpdateClient = (data) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL+"client/update",
            data: data,
        })
        .then((res)=>{
            return res;
        })
        .catch((err)=>{
            Rendertoast.showToast({ "type": "e", "msg": err ? err.message ? err.message : err : "Something went wrong" });
        })
    } catch (error) {
        
    }
}
//Api to genrate new client
export const NewClient = (data) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL+"client/new",
            data: data,
        })
        .then((res)=>{
            return res;
        })
        .catch((err)=>{
            Rendertoast.showToast({ "type": "e", "msg": err ? err.message ? err.message : err : "Unable to add project name" });
        })
    } catch (error) {
        
    }
}