import React, { useEffect, useState } from 'react';
import "react-circular-progressbar/dist/styles.css";
import { RightArrow } from './icons/right-arrow';
export const FooterWithBackNext = (props) => {
    const [isLastPage, setLastPage] = useState(false)

    function validateForm() {
        if (props.validateForm) {
            props.validateForm()
        }
    }

    useEffect(() => {
        if(props.text) {
            var valArray = (props.text).split("/");
            valArray = valArray.map((val) => {parseInt(val); return val});
            setLastPage(valArray[0] === valArray[1] ? true : false)
        }
    }, [])

    return (
        <footer className="footer mobile:p-[10px] py-[48px] px-[88px]">
            <div className="footer-layer">
                <div className="footer-split">
                    <div className="rounded-lg [@media(max-width:1000px)]:flex hidden items-center justify-start">
                        {props.prevStep && <button type="button" onClick={() => props.prevStep()} className="foot-back-btn-sm hover-text-black">Back</button>}
                    </div>
                    <div className="flex-1 rounded-lg flex items-center justify-between [@media(max-width:1000px)]:justify-end">
                        {!props.prevStep && <div></div>}
                        <div className='flex'>
                            {props.prevStep && <button type="button" onClick={() => props.prevStep()} className="inline-flex [@media(max-width:1000px)]:hidden items-center px-[24px] py-[12px] text-base font-bold shadow-sm focus:outline-none h-[48px] mr-4 rounded-border-button hover:bg-[#F5F5F5] dark:hover:!bg-[#4C4C4C]">Back</button>}
                            {props.showBookCall && <button type="button" className="foot-back-btn-sm hover-text-black">Book a guided tour
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='headset-icon ml-[10px]'>
                                    <path d="M10 1.63525C5.38768 1.63525 1.63525 5.38768 1.63525 10V13.4655C1.63525 14.3221 2.38557 15.0188 3.3082 15.0188H4.14468C4.36652 15.0188 4.57928 14.9307 4.73615 14.7738C4.89302 14.617 4.98115 14.4042 4.98115 14.1824V9.88038C4.98115 9.65854 4.89302 9.44577 4.73615 9.28891C4.57928 9.13204 4.36652 9.04391 4.14468 9.04391H3.38516C3.85024 5.80675 6.6357 3.3082 10 3.3082C13.3643 3.3082 16.1498 5.80675 16.6148 9.04391H15.8553C15.6335 9.04391 15.4207 9.13204 15.2638 9.28891C15.107 9.44577 15.0188 9.65854 15.0188 9.88038V15.0188C15.0188 15.9415 14.2685 16.6918 13.3459 16.6918H11.6729V15.8553H8.32705V18.3647H13.3459C15.1912 18.3647 16.6918 16.8641 16.6918 15.0188C17.6144 15.0188 18.3647 14.3221 18.3647 13.4655V10C18.3647 5.38768 14.6123 1.63525 10 1.63525Z" fill="#0E0E0E"/>
                                </svg>
                            </button>}
                        </div>
                        <div className='[@media(max-width:1000px)]:flex'>
                            <div></div>
                            {props.showSkip && <button type="button" onClick={() => !isLastPage ? props.skipStep() : props.lastPage()} className="foot-skip-btn rounded-border-button"><span className="font-bold"><span className='font-bold'>{isLastPage ? 'Skip & done' : 'Skip'}</span><span className="[@media(max-width:1000px)]:hidden">,  you can do this later on your profile page</span></span></button>}
                            {props.showDone ?
                                <button onClick={() => props.submitHandler()} className={'foot-next-btn'} > Done 
                                    <span className="ml-3">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='tick-icon-footer' >
                                            <path d="M7.99651 15.6011C12.1179 15.6011 15.5 12.2259 15.5 8.10456C15.5 3.98322 12.1179 0.601074 7.99651 0.601074C3.87517 0.601074 0.5 3.98322 0.5 8.10456C0.5 12.2259 3.87517 15.6011 7.99651 15.6011ZM7.2364 11.6471C6.88773 11.6471 6.60879 11.4867 6.37169 11.2008L4.79568 9.34584C4.60042 9.12269 4.53766 8.94138 4.53766 8.71126C4.53766 8.21614 4.94212 7.83259 5.42329 7.83259C5.70223 7.83259 5.91841 7.94417 6.11367 8.1743L7.22245 9.49229L9.80265 5.41976C10.0188 5.08503 10.2629 4.9107 10.5837 4.9107C11.0649 4.9107 11.4972 5.26635 11.4972 5.75449C11.4972 5.94278 11.4344 6.15198 11.295 6.34724L8.07322 11.1799C7.87796 11.4728 7.57113 11.6471 7.2364 11.6471Z" fill="white" />
                                        </svg>
                                    </span>
                                </button> 
                                : 
                                <button onClick={() => props.disabled ? validateForm() : props.submitHandler()} className={props.disabled ? 'foot-next-btn btn-disable' : 'foot-next-btn'} > Next <span className='ml-3 [@media(min-width:1000px)]:hidden block'><RightArrow /></span>
                                    <div className="ml-3 [@media(min-width:1000px)]:block hidden"><RightArrow /></div>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}