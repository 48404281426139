import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
export default function FormFieldError(props) {

    return (
        <>
            {props.errors[props.fieldname]
                ?
                <div className="form-error">
                    <ErrorMessage
                        errors={props.errors}
                        name={props.fieldname}
                        render={({ messages }) =>
                            messages && <span>{messages[Object.keys(messages)[0]]}. </span>
                        }
                    />
                </div>
                :
                <div className="form-error">
                    <span>&nbsp;</span>
                </div>
            }
        </>
    )
}

export function SelectFormFieldError(props) {
    return (
        <>
            {!props.isValid
                ?
                <div className="form-error">
                    <span>{props.message}. </span>
                </div>
                :
                <div className="form-error">
                    <span>&nbsp;</span>
                </div>
            }
        </>
    )
}