import { ClientOnboardingTemplate } from "../common/client-onboarding-template"
import { Header } from "../common/header"
import { TextContainer } from "../common/text-container";
import { RightArrow } from "../common/icons/right-arrow";
import { EyeIcon } from "../common/icons/eye-icon";
import { useState } from "react";

export const Clientlogin = (props) => {
    const head = "10, 9, 8… <b class='text-[48px] font-bold'>Launch a revolution</b>.";
    const subHead = "Sign up, align the superstars, access some of the world's brightest brains, founder-led agencies and award-winning creatives.<br></br>This is your chance to work with the planet's most exclusive group of creative talent. To take the take the moon shoots, change the business of creativity and give your brand an unreasonable advantage.";
    const [showPassword,setShowPassword] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    function handleShowhide(){
        if(showPassword){
            setShowPassword(false);
        }else{
            setShowPassword(true);
        }
    }

    function handleConfirmPass(){
        if(showConfirmPass){
            setShowConfirmPass(false);
        }else{
            setShowConfirmPass(true);
        }
    }

    return(
        <div className="page-section">
            <Header isClient={true}></Header>
            <ClientOnboardingTemplate>
                <div className="login-textContainer">
                    {/* <div className="w-[236px] h-[168px]"><SpiralImage/></div> */}
                    <TextContainer head={head} subHead={subHead} activeTab="Login" activepersentage="0%" text="1/7"/>
                </div>
                <div>
                    <form className="client-login w-full py-4 space-y-11" autoComplete="off">
                        <div className="field-group mt-[40px] w-full">
                            <input type="text" name="email" placeholder=" " className="input-fld" />
                            <label htmlFor="email" className="input-lbl">Email</label>
                        </div>
                        <div className="field-group mt-[40px] w-full">
                            <input type={showPassword ? "text" : "password"} name="password" placeholder=" " className="input-fld" />
                            <label htmlFor="password" className="input-lbl">Password</label>
                            <span className="absolute right-[4px] bottom-[8px]" onClick={handleShowhide}><EyeIcon/></span>
                        </div>
                        <div className="field-group mt-[40px] w-full">
                            <input type={showConfirmPass ? "text" : "password"} name="confirmpassword" placeholder=" " className="input-fld" />
                            <label htmlFor="confirmpassword" className="input-lbl">Confirm Password</label>
                            <span className="absolute right-[4px] bottom-[8px]" onClick={handleConfirmPass}><EyeIcon/></span>
                        </div>
                    </form>
                    <div className="relative !mt-[18px] flex flex-col gap-4 w-full">
                        <div className='text-left'><input type="checkbox" id="terms" name="terms" value="Bike"/><label htmlFor="terms" className='p-[14px] text-color-4C4C4C dark:text-[#FFF]'>Yes, i agree to recive carrusela news and update.</label></div>
                        <div className='text-left'><input type="checkbox" id="terms" name="terms" checked readOnly value="Bike"/><label htmlFor="terms" className='p-[14px] text-color-4C4C4C font-normal dark:text-[#FFF]'>Yes, i agree to the terms and privacy policy.</label></div>
                    </div>
                    <div className='w-full mt-[32px]'>
                        <button onClick={props.nextStep} type="button" className=" w-full text-center flex items-center rounded-full bg-[#121212] text-white dark:bg-white dark:text-[black] text-[16px] px-8 py-3  font-bold shadow-sm h-[48px] mr-4 justify-center">Create account <span className="pl-3"><RightArrow/></span></button>
                    </div>
                    <div className='w-full mt-[16px] text-left'>
                        <p className='text-[16px] font-normal text-color-4C4C4C dark:text-[#FFF]'>Already have an account? please <a href="/login"><b className='text-[16px] dark:text-[#FFF] font-bold'>Log in.</b></a></p>
                    </div>
                </div>          
            </ClientOnboardingTemplate>
        </div>
    )
}