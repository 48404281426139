import React from 'react'

export const RightArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4417_34088)">
        <path className=' dark:fill-black dark:stroke-black fill-white stroke-white' d="M14.0384 18.1788L13.8636 18.3496L13.6889 18.1788L12.7275 17.2388L12.5448 17.0601L12.7274 16.8814L16.776 12.9167L5 12.9167H4.75V12.6667V11.3333V11.0833H5L16.776 11.0833L12.7274 7.11862L12.5448 6.93986L12.7275 6.76125L13.6889 5.82125L13.8636 5.65035L14.0384 5.82125L20.1748 11.8212L20.3576 12L20.1748 12.1788L14.0384 18.1788Z" fill="#090909" stroke="#090909" strokeWidth="0.5" />
    </g>
    <defs>
        <clipPath id="clip0_4417_34088">
            <rect width="24" height="24" fill="white" />
        </clipPath>
    </defs>
</svg>
  )
}
