import React, { useEffect, useState } from 'react';
import common from '../../../../utils/common';


export const Matchingprojects = (props) => {
    const id = props.id;
    const [workDetails, setWorkDetails] = useState([]);
    const [worksMoreExpanded, setWorksMoreExpanded] = useState(false);

    useEffect(() => {
        if (props.editDetails) {
            var userDetails;
            var temp = props.type === 'agency' ? props.editDetails.filter(val => val.agency_id === id) : props.editDetails.filter(val => val.user_id === id);
            if (temp && temp.length) {
                userDetails = temp[0];
                if (userDetails.works && userDetails.works.length) {
                    setWorkDetails(userDetails.works);
                }
            }
        }
    }, [])

    return (
        <div className={`${workDetails.length ? "relative" : "hidden"}`}>
    <hr className='my-[88px]'></hr>
    <div >
            <span className='text-[36px] font-bold'>Work from {props.name}</span>
            {!worksMoreExpanded && <div className='pt-[32px] grid grid-cols-2 gap-[48px]'>
                {workDetails.slice(0, 4).map((work, i) => {
                    return (
                        <div className='flex flex-col gap-[4px]'>
                            <div className="h-[257px] [@media(max-width:720px)]:h-[157px] project-pic">
                                {work.creatives && work.creatives.length ? common.checkIsMedia(work.creatives[0].url, ['video/mp4', 'video/mov',"mp4"]) && (work.creatives[0].poster_url === "")
                                    ? <video className={`w-full media-img`} height="257">
                                        <source src={work.creatives[0].url}></source>
                                    </video> :
                                    <div style={{ backgroundImage: `url(${!(common.checkIsMedia(work.creatives[0].url, ['video/mp4', 'video/mov',"mp4"])) ? work.creatives[0].url : work.creatives[0].poster_url})` }} className={`w-full h-full media-img`}>
                                    </div>
                                    : <span className="bg-[#F5F5F5] dark:bg-[#333333] block w-full h-full [@media(max-width:720px)]:rounded-[64px]"></span>}
                            </div>
                            <span className='text-[24px] pt-[20px] font-bold tracking-[-0.72px] leading-[100%] capitalize'>{work.name ? work.name : 'Project name'}</span>
                            <span className='text-[18px] leading-[130%] tracking-[-0.54px]'>{work.company && work.company.label}</span>
                        </div>
                    )
                })}
            </div>}
            {worksMoreExpanded && <div className='pt-[32px] grid grid-cols-2 gap-[48px]'>
                {workDetails.map((work, i) => {
                    return (
                        <div className='flex flex-col gap-[4px]'>
                            <div className="h-[257px] [@media(max-width:720px)]:h-[157px] project-pic">
                                {work.creatives && work.creatives.length ? common.checkIsMedia(work.creatives[0].url, ['video/mp4', 'video/mov',"mp4"]) && (work.creatives[0].poster_url === "")
                                    ? <video className={`w-full media-img`} height="257">
                                        <source src={work.creatives[0].url}></source>
                                    </video> :
                                    <div style={{ backgroundImage: `url(${!(common.checkIsMedia(work.creatives[0].url, ['video/mp4', 'video/mov',"mp4"])) ? work.creatives[0].url : work.creatives[0].poster_url})` }} className={`w-full h-full media-img`}>
                                    </div>
                                    : <span className="bg-[#F5F5F5] dark:bg-[#333333] block w-full h-full  [@media(max-width:720px)]:rounded-[64px]"></span>}
                            </div>
                            <span className='text-[24px] pt-[20px] font-bold tracking-[-0.72px] leading-[100%] capitalize'>{work.name ? work.name : 'Project name'}</span>
                            <span className='text-[18px] leading-[130%] tracking-[-0.54px]'>{work.company && work.company.label}</span>
                        </div>
                    )
                })}
            </div>}
            {workDetails.length > 4 && <div className='w-full flex justify-center'>
                <button className='mt-[42px] border-[1px] text-[16px] border-[#1d1d1d] px-[24px] font-bold rounded-full py-[12px]' onClick={(e) => { setWorksMoreExpanded(!worksMoreExpanded) }}>{worksMoreExpanded ? 'Load less' : 'Load More'}</button>
            </div>}
        </div></div>)
}
