import React, { } from 'react';
import '../component/css/loader.css';
export default function DynamicLoader(props) {
    return (
        <>
            {props.type === "apiloading"
                ?
                <div className="dash-widget-loader no-data">{"Fetching " + props.name + "..."} </div>
                :
                <div className="loader" data-name={props.name}>
                    <div className="loader-inner">

                        <div className="logoloader-img"><svg xmlns="http://www.w3.org/2000/svg" width="57" height="50" viewBox="0 0 57 50" fill="none">
                            <path d="M45.8107 0C39.293 0 0.5 0.25996 0.5 0C0.5 0 0.5 33.1338 0.5 37.5481C0.5 42.4692 5.96149 43.4506 6.45928 43.5302C6.50177 43.9333 7.25148 50 14.0566 50C17.446 50 56.5 50 56.5 50C56.5 50 56.5 17.0244 56.5 10.9052C56.5 3.89537 52.3285 0 45.8107 0ZM13.2533 34.5163H9.48444C9.48444 34.5163 9.48444 21.6261 9.48444 19.5051C9.48444 15.0999 13.2533 15.0999 13.2533 15.0999V34.5173V34.5163ZM44.2931 34.5163V15.0999H27.3977C27.6385 15.1049 31.1533 15.251 31.1533 19.5051V34.5163H27.3845V15.0999H13.2968C13.2968 15.0999 13.2968 11.3466 17.7202 11.3466H22.697C26.047 11.3466 26.8594 13.4978 27.0577 14.5427C27.255 13.4978 28.0685 11.3466 31.4184 11.3466H39.9587C44.3821 11.3466 44.3821 15.0999 44.3821 15.0999H44.3062C44.547 15.1049 48.0609 15.251 48.0609 19.5051C48.0609 21.6261 48.0609 34.5163 48.0609 34.5163H44.2921H44.2931Z" fill="#1D1D1D" />
                        </svg></div>
                        <div className="loader-img1"><svg xmlns="http://www.w3.org/2000/svg" width="291" height="253" viewBox="0 0 291 253" fill="none">
                            <path d="M28.5668 205.434L0.990708 36.0789C1.31656 36.0506 1.72604 36.0069 2.21165 35.9492C3.5264 35.7932 5.44587 35.5298 7.88715 35.1737C12.7719 34.4611 19.7694 33.3737 28.2491 32.0213C45.2096 29.3163 68.1121 25.5493 91.9302 21.5953C115.694 17.6504 140.369 13.5196 160.966 10.0714C181.66 6.60691 198.239 3.83144 205.643 2.62585C220.27 0.244148 232.89 2.67325 242.48 9.54767C252.068 16.4212 258.728 27.8118 261.291 43.5536L290.03 220.047L98.3556 251.257C83.1982 253.725 73.762 248.349 68.0315 242.183C62.2652 235.979 60.2141 228.928 59.9819 228.078L59.8307 227.524L59.2563 227.525C58.1349 227.528 51.2997 227.427 44.3189 224.589C37.3641 221.761 30.3285 216.254 28.5668 205.434Z" stroke="#4F4F4F" strokeWidth="1.5" />
                        </svg></div>
                        <div className="loader-img2"><svg xmlns="http://www.w3.org/2000/svg" width="291" height="253" viewBox="0 0 291 253" fill="none">
                            <path d="M28.5668 205.434L0.990708 36.0789C1.31656 36.0506 1.72604 36.0069 2.21165 35.9492C3.5264 35.7932 5.44587 35.5298 7.88715 35.1737C12.7719 34.4611 19.7694 33.3737 28.2491 32.0213C45.2096 29.3163 68.1121 25.5493 91.9302 21.5953C115.694 17.6504 140.369 13.5196 160.966 10.0714C181.66 6.60691 198.239 3.83144 205.643 2.62585C220.27 0.244148 232.89 2.67325 242.48 9.54767C252.068 16.4212 258.728 27.8118 261.291 43.5536L290.03 220.047L98.3556 251.257C83.1982 253.725 73.762 248.349 68.0315 242.183C62.2652 235.979 60.2141 228.928 59.9819 228.078L59.8307 227.524L59.2563 227.525C58.1349 227.528 51.2997 227.427 44.3189 224.589C37.3641 221.761 30.3285 216.254 28.5668 205.434Z" stroke="#4F4F4F" strokeWidth="1.5" />
                        </svg></div>
                        <div className="loader-img3"><svg xmlns="http://www.w3.org/2000/svg" width="291" height="253" viewBox="0 0 291 253" fill="none">
                            <path d="M28.5668 205.434L0.990708 36.0789C1.31656 36.0506 1.72604 36.0069 2.21165 35.9492C3.5264 35.7932 5.44587 35.5298 7.88715 35.1737C12.7719 34.4611 19.7694 33.3737 28.2491 32.0213C45.2096 29.3163 68.1121 25.5493 91.9302 21.5953C115.694 17.6504 140.369 13.5196 160.966 10.0714C181.66 6.60691 198.239 3.83144 205.643 2.62585C220.27 0.244148 232.89 2.67325 242.48 9.54767C252.068 16.4212 258.728 27.8118 261.291 43.5536L290.03 220.047L98.3556 251.257C83.1982 253.725 73.762 248.349 68.0315 242.183C62.2652 235.979 60.2141 228.928 59.9819 228.078L59.8307 227.524L59.2563 227.525C58.1349 227.528 51.2997 227.427 44.3189 224.589C37.3641 221.761 30.3285 216.254 28.5668 205.434Z" stroke="#4F4F4F" strokeWidth="1.5" />
                        </svg></div>
                        <div className="loader-img4"><svg xmlns="http://www.w3.org/2000/svg" width="291" height="253" viewBox="0 0 291 253" fill="none">
                            <path d="M28.5668 205.434L0.990708 36.0789C1.31656 36.0506 1.72604 36.0069 2.21165 35.9492C3.5264 35.7932 5.44587 35.5298 7.88715 35.1737C12.7719 34.4611 19.7694 33.3737 28.2491 32.0213C45.2096 29.3163 68.1121 25.5493 91.9302 21.5953C115.694 17.6504 140.369 13.5196 160.966 10.0714C181.66 6.60691 198.239 3.83144 205.643 2.62585C220.27 0.244148 232.89 2.67325 242.48 9.54767C252.068 16.4212 258.728 27.8118 261.291 43.5536L290.03 220.047L98.3556 251.257C83.1982 253.725 73.762 248.349 68.0315 242.183C62.2652 235.979 60.2141 228.928 59.9819 228.078L59.8307 227.524L59.2563 227.525C58.1349 227.528 51.2997 227.427 44.3189 224.589C37.3641 221.761 30.3285 216.254 28.5668 205.434Z" stroke="#4F4F4F" strokeWidth="1.5" />
                        </svg></div>
                        <div className="loader-img5"><svg xmlns="http://www.w3.org/2000/svg" width="291" height="253" viewBox="0 0 291 253" fill="none">
                            <path d="M28.5668 205.434L0.990708 36.0789C1.31656 36.0506 1.72604 36.0069 2.21165 35.9492C3.5264 35.7932 5.44587 35.5298 7.88715 35.1737C12.7719 34.4611 19.7694 33.3737 28.2491 32.0213C45.2096 29.3163 68.1121 25.5493 91.9302 21.5953C115.694 17.6504 140.369 13.5196 160.966 10.0714C181.66 6.60691 198.239 3.83144 205.643 2.62585C220.27 0.244148 232.89 2.67325 242.48 9.54767C252.068 16.4212 258.728 27.8118 261.291 43.5536L290.03 220.047L98.3556 251.257C83.1982 253.725 73.762 248.349 68.0315 242.183C62.2652 235.979 60.2141 228.928 59.9819 228.078L59.8307 227.524L59.2563 227.525C58.1349 227.528 51.2997 227.427 44.3189 224.589C37.3641 221.761 30.3285 216.254 28.5668 205.434Z" stroke="#4F4F4F" strokeWidth="1.5" />
                        </svg></div>
                        <div className="loader-img6"><svg xmlns="http://www.w3.org/2000/svg" width="291" height="253" viewBox="0 0 291 253" fill="none">
                            <path d="M28.5668 205.434L0.990708 36.0789C1.31656 36.0506 1.72604 36.0069 2.21165 35.9492C3.5264 35.7932 5.44587 35.5298 7.88715 35.1737C12.7719 34.4611 19.7694 33.3737 28.2491 32.0213C45.2096 29.3163 68.1121 25.5493 91.9302 21.5953C115.694 17.6504 140.369 13.5196 160.966 10.0714C181.66 6.60691 198.239 3.83144 205.643 2.62585C220.27 0.244148 232.89 2.67325 242.48 9.54767C252.068 16.4212 258.728 27.8118 261.291 43.5536L290.03 220.047L98.3556 251.257C83.1982 253.725 73.762 248.349 68.0315 242.183C62.2652 235.979 60.2141 228.928 59.9819 228.078L59.8307 227.524L59.2563 227.525C58.1349 227.528 51.2997 227.427 44.3189 224.589C37.3641 221.761 30.3285 216.254 28.5668 205.434Z" stroke="#4F4F4F" strokeWidth="1.5" />
                        </svg></div>

                    </div>
                </div>
            }
        </>
    )
}