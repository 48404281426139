import React from 'react';
// import layerdark from '../../../assets/img/LayerDark.png';
// import layer from '../../../assets/img/layer.png';
// import greenicon from '../../../assets/icons/left-icon.svg';
import { ProgressBar } from './progress-bar';

export const TextContainer = (props) => {
  // let firstWord = props.head.substring(0, props.head.lastIndexOf(" "));
  // let lastWord = props.head.split(" ").pop();

  return (
    <>
      {/* <img className='dark:hidden [@media(max-width:650px)]:w-[128px] [@media(max-width:650px)]:h-[87px] [@media(max-width:1000px)]:mx-auto' src={layer} alt='layer'/>
      <img className='hidden dark:block [@media(max-width:650px)]:w-[128px] [@media(max-width:650px)]:h-[87px] [@media(max-width:1000px)]:mx-auto' src={layerdark} alt='layer'/> */}
      {/* <hr className='dark:border-[#4c4c4c] border-[#d9d9d9] my-[25px]'/> */}
      <div className='flex'>
        <div className='mr-[10px] [@media(min-width:741px)]:block hidden'><svg width="20" height="45" viewBox="0 0 20 45" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.13654 10.0672L16.6827 22.4457C16.6827 22.4457 22.6792 17.6711 16.3483 12.6301C14.3898 11.0708 6.41555 4.72135 0.976562 0.390625L1.13654 10.0672Z" fill="#11F930"/><path d="M1.13653 34.8238L16.6827 22.4453C16.6827 22.4453 22.6792 27.2199 16.3482 32.2608C14.3898 33.8202 6.41555 40.1696 0.976562 44.5003L1.13653 34.8238Z" fill="#11F930"/></svg></div>
        <div className='w-[474px]'>
          <h1 className="form-head archivo flex flex-row gap-[16px] items-start"><div className='mr-[10px] [@media(max-width:740px)]:block hidden'><svg width="20" height="45" viewBox="0 0 20 45" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.13654 10.0672L16.6827 22.4457C16.6827 22.4457 22.6792 17.6711 16.3483 12.6301C14.3898 11.0708 6.41555 4.72135 0.976562 0.390625L1.13654 10.0672Z" fill="#11F930"/><path d="M1.13653 34.8238L16.6827 22.4453C16.6827 22.4453 22.6792 27.2199 16.3482 32.2608C14.3898 33.8202 6.41555 40.1696 0.976562 44.5003L1.13653 34.8238Z" fill="#11F930"/></svg></div>
            <span className='text-[48px] font-extralight leading-[120%] inline-block align-center normal-case' dangerouslySetInnerHTML={{ __html: props.head }}></span>
          </h1>
          <p className='form-sub-head archivo' dangerouslySetInnerHTML={{ __html: props.subHead }}></p>
          <div className='block w-full'>
            <ProgressBar activeTab={props.activeTab} activeTabPersentage={props.activepersentage} text={props.text} />
          </div>
        </div>
      </div>

    </>
  )
}
