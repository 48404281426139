import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Session from 'supertokens-auth-react/recipe/session';
import { useNavigate } from "react-router-dom";
import DynamicLoader from "../../utils/dynamicLoader";
import { Clientlogin } from "./client-onboarding/clientlogin";
import { RoleAndIndustry } from "./client-onboarding/role";
import { Skills } from "./client-onboarding/skills";
import { Experience } from "./client-onboarding/experience";
import { CurrentBrand } from "./client-onboarding/current-brand";
import { BrandsAndCreative } from "./client-onboarding/brands";
import { Team } from "./client-onboarding/team";
import { GetBrands, GetIndustry, GetLocation, GetRole, GetSkills } from "../service/commonapi";
import { GetTone } from "../service/talent-onboardig-api";
import { FormDataUpdateClient } from "../service/client-onboarding-api";

function ClientOnboarding(props){
    const selectStore = useSelector(state => state.selectStore);
    const [showloader, setshowloader]= useState(false);
    const [steps, setsteps] = useState(0);
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isMounted.current) {
            //setshowloader(true)
            doesSessionExist();
            callAllSelect();
        }
    }, []);

    async function doesSessionExist() {
        if (await Session.doesSessionExist()) {
        } else {
            navigate("/login");
        }
    }

    function nextStep() {
        setsteps(x => x + 1 )
    }

    function prevStep() {
        //FormDataUpdateClient({"input":{"onboarding_progress":steps-1}}).then((res) => {
            setsteps(x => x - 1)
        //})
    }

    function skipStep(){
        setsteps(x => x + 1)
    }

    function saveData(datas){
        // var _datas = JSON.parse(JSON.stringify(datas));
        // FormDataUpdateClient({"input":_datas}).then((res) => {
        //     console.log(res)
        // })
    }

    function lastPage() {
        setshowloader(true);
    }

    function callAllSelect(){ 
        try {
            GetSkills(dispatch,selectStore)
            GetIndustry(dispatch,selectStore)
            GetBrands(dispatch, selectStore)
            GetTone(dispatch,selectStore)
            GetRole(dispatch,selectStore)
            GetLocation(dispatch,selectStore)
        } catch (error) {
            setshowloader(false);console.log(error)
        }
    }

    if(showloader){return (<DynamicLoader name="Login"></DynamicLoader>)}
    switch (steps) {
        case 0:
            return (<Clientlogin nextStep={nextStep} />);

        case 1:
            return (<RoleAndIndustry saveData={saveData} prevStep={prevStep} nextStep={nextStep} />);

        case 2:
            return (<Skills saveData={saveData} prevStep={prevStep} nextStep={nextStep} />);

        case 3:
            return (<Experience saveData={saveData} prevStep={prevStep} nextStep={nextStep} skipStep={skipStep} />);

        case 4:
            return (<CurrentBrand saveData={saveData} prevStep={prevStep} nextStep={nextStep} skipStep={skipStep} />)
        
        case 5:
            return (<BrandsAndCreative saveData={saveData} prevStep={prevStep} nextStep={nextStep} skipStep={skipStep} />)

        case 6:
            return (<Team saveData={saveData} prevStep={prevStep} nextStep={nextStep} skipStep={skipStep} lastPage={lastPage} />);
            
        default:
            return (<RoleAndIndustry saveData={saveData}  nextStep={nextStep} />);
    }
}
export default ClientOnboarding;