import React from 'react';
import { Tick } from '../component/view/common/icons/tick';
export default function FormValidationIcon(props) {
    return (
        <>
            {props.twolevelselect ? (props.minlen ? (props.isArray && Array.isArray(props.getval) && props.getval.length >= props.minlen) && <span className="absolute bottom-[8px] right-[1px]"><Tick /></span> : "") : (props.isArray
                ? (props.minlen ? (props.isArray && Array.isArray(props.getval) && props.getval.length === props.minlen) && props.fieldTouched[props.fieldname] && !props.errors[props.fieldname] && <span className="absolute bottom-[8px] right-[1px]"><Tick /></span> : (props.isArray && Array.isArray(props.getval) && props.getval.length !== 0) && props.fieldTouched[props.fieldname] && !props.errors[props.fieldname] && <span className="absolute bottom-[8px] right-[1px]"><Tick /></span>)
                : (props.getval !== "") && props.fieldTouched[props.fieldname] && !props.errors[props.fieldname] && <span className="absolute bottom-[8px] right-[1px]"><Tick /></span>)}
        </>
    )
}
