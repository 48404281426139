import { useDispatch, useSelector } from "react-redux"
import { ClientOnboardingTemplate } from "../common/client-onboarding-template"
import { FooterWithBackNext } from "../common/footer-with-back-next"
import { Header } from "../common/header"
import { TextContainer } from "../common/text-container"
import { useEffect, useRef, useState } from "react"
import common from "../../../utils/common"
import { SelectFormFieldError } from "../../../utils/error-message"
import FormValidationIcon from "../../../utils/form-validation-icon"
import { Dropdown } from "../common/icons/dropdown"
import { useForm } from "react-hook-form"

export const Skills = (props) => {
    const head = "What kind of skills <b class='text-[48px] font-bold'>are you hungry for</b>?";
    const subHead = "Today, tomorrow and most importantly for the future. What are type of creative and lateral thinking will help you and your brands excel?";
    const clientStore = useSelector(state => state.clientStore);
    const selectStore = useSelector(state => state.selectStore);
    const [skilldrop, setskilldrop] = useState([]);
    const [primarySkill, setprimarySkill] = useState([]);
    const [secondarySkill, setsecondarySkill] = useState([]);
    const [priSkillIsvalid, setPriSkillIsvalid] = useState(true);
    const [priSkillErrorMessage, setPriSkillErrorMsg] = useState("");
    const [secSkillIsvalid, setSecSkillIsvalid] = useState(true);
    const [secSkillErrorMessage, setSecSkillErrorMsg] = useState("");
    const primaryParentRef = useRef(null)
    const secondaryParentRef = useRef(null)
    const primaryParentRefdrop = useRef(null)
    const secondaryParentRefdrop = useRef(null)
    const dispatch = useDispatch();
    const { trigger, formState: { isValid} } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all"
    });

    useEffect(() => {
        var _skill = groupBy(selectStore.skils, 'label');
        var valSkill = []
        Object.entries(_skill).forEach((v) => {
          var _val = {};
          _val.label = v[0];
          var key = v[1].filter(el => !el.sublabel);
          _val.value = key[0].skill_id;
          _val.sublist = [];
          v[1].forEach((skills) => {
            var _skills = JSON.parse(JSON.stringify(skills));
            _skills["isChecked"] = false;
            _skills["isPrimary"] = false;
            _skills["isSecondary"] = false;
            _val.sublist.push(_skills)
          })
          valSkill.push(_val)
        })
        Object.entries(clientStore.page3).forEach(val => {
          if (val[1] && (val[1] !== "" || (Array.isArray(val[1]) && val[1].length))) {
            var primaryskill = []; var secondaryskill = [];
            if (val[0] === "skills") {
                val[1].forEach(_val => {
                var index = selectStore.skils.findIndex(x => x.skill_id === _val.skill_id);
                if (index !== -1) {
                  var _value = selectStore.skils[index].sublabel ? selectStore.skils[index].sublabel : selectStore.skils[index].label;
                  if (_val.is_primary) {
                    primaryskill.push(_value)
                    valSkill.forEach((_inds, _id) => {
                      _inds.sublist.forEach((inds, id) => {
                        if (inds.skill_id === _val.skill_id) {
                          valSkill[_id].sublist[id]["isChecked"] = true;
                          valSkill[_id].sublist[id]["isPrimary"] = true;
                          return;
                        }
                      })
                    })
                  } else {
                    secondaryskill.push(_value)
                    valSkill.forEach((_inds, _id) => {
                      _inds.sublist.forEach((inds, id) => {
                        if (inds.skill_id === _val.skill_id) {
                          valSkill[_id].sublist[id]["isChecked"] = true;
                          valSkill[_id].sublist[id]["isSecondary"] = true;
                          return;
                        }
                      })
                    })
                  }
                }
                })
                setprimarySkill(primaryskill);
                setsecondarySkill(secondaryskill);
            }
          }
        })
        if (Array.isArray(valSkill) && valSkill.length !== 0) { setskilldrop(valSkill); }
        window.addEventListener('resize', resizeListener);
        return () => {
          window.removeEventListener('resize', resizeListener);
        }
    }, [selectStore.skils]);

    useEffect(() => {
        var parent = primaryParentRef.current;
        if(parent) {
            parent = parent.getBoundingClientRect();
        }
        document.querySelector('.skil-primary.demoFixed').style.top = parent.top + 10 + 'px';
    }, [primarySkill])

    useEffect(() => {
        var parent = secondaryParentRef.current;
        if(parent) {
            parent = parent.getBoundingClientRect();
            document.querySelector('.skil-secondary.demoFixed').style.top = parent.top + 10 + 'px';
        }
    }, [secondarySkill])

    /**PRIMARY SKILL SELECT CHECKBOX ONCHANGE */
    function handlePrimarySkillSelectOnchangeCheckbox(val, checkval, i, _i, e) {
        if(e) {
        e.preventDefault()
        e.stopPropagation();
        }
        var _val = JSON.parse(JSON.stringify(skilldrop));
        if (_val[i].sublist[_i]["isChecked"]) {
        _val[i].sublist[_i]["isChecked"] = false;
        _val[i].sublist[_i]["isPrimary"] = false;
        } else {
        _val[i].sublist[_i]["isChecked"] = true;
        _val[i].sublist[_i]["isPrimary"] = true;
        }
        setskilldrop(_val);
        var _primaryskill = JSON.parse(JSON.stringify(primarySkill));
        var _value = checkval.sublabel ? checkval.sublabel : checkval.label
        var _index = primarySkill.indexOf(_value)
        if (_index === -1) { _primaryskill.push(_value); } else { _primaryskill.splice(_index, 1) }
        setprimarySkill(_primaryskill);
        roleSelectValidation(_primaryskill, 2, 'Primary skills', 'primary')
        onChangeData(_primaryskill, secondarySkill)
        if(_primaryskill && _primaryskill.length >=2){
        disableallLevel()
        }
    }

    /**PRIMARY SKILL SELECT ONCLOSE */
    function handlePrimarySkillSelectOnClose(_value) {
        var _val = JSON.parse(JSON.stringify(skilldrop));
        _val.forEach((_inds, _id) => {
        _inds.sublist.forEach((inds, id) => {
            var value = inds.sublabel ? inds.sublabel : inds.label
            if (value === _value) {
            _val[_id].sublist[id]["isChecked"] = false;
            _val[_id].sublist[id]["isPrimary"] = false;
            return;
            }
        })
        })
        setskilldrop(_val);
        var _primaryskill = JSON.parse(JSON.stringify(primarySkill));
        _primaryskill.splice(_primaryskill.indexOf(_value), 1);
        setprimarySkill(_primaryskill);
        roleSelectValidation(_primaryskill, 2, 'Primary skills', 'primary')
        onChangeData(_primaryskill, secondarySkill)
    }

    /**SECONDARY SKILL SELECT CHECKBOX ONCHANGE */
    function handleSecondarySkillSelectOnchangeCheckbox(val, checkval, i, _i, e) {
        if(e) {
        e.preventDefault()
        e.stopPropagation();
        }
        var _val = JSON.parse(JSON.stringify(skilldrop));
        if (_val[i].sublist[_i]["isChecked"]) {
        _val[i].sublist[_i]["isChecked"] = false;
        _val[i].sublist[_i]["isSecondary"] = false;
        } else {
        _val[i].sublist[_i]["isChecked"] = true;
        _val[i].sublist[_i]["isSecondary"] = true;
        }
        setskilldrop(_val);
        var _secondartskill = JSON.parse(JSON.stringify(secondarySkill));
        var _value = checkval.sublabel ? checkval.sublabel : checkval.label
        var _index = secondarySkill.indexOf(_value)
        if (_index === -1) { _secondartskill.push(_value); } else { _secondartskill.splice(_index, 1) }
        setsecondarySkill(_secondartskill)
        roleSelectValidation(_secondartskill, 2, 'Secondary skills', 'secondary')
        onChangeData(primarySkill, _secondartskill)
        if(_secondartskill && _secondartskill.length >=2){
        disableallLevelSecondary()
        }
    }

    /**SECONDARY SKILL SELECT ONCLOSE */
    function handleSecondarySkillSelectOnClose(_value) {
        var _val = JSON.parse(JSON.stringify(skilldrop));
        _val.forEach((_inds, _id) => {
        _inds.sublist.forEach((inds, id) => {
            var value = inds.sublabel ? inds.sublabel : inds.label
            if (value === _value) {
            _val[_id].sublist[id]["isChecked"] = false;
            _val[_id].sublist[id]["isSecondary"] = false;
            return;
            }
        })
        })
        setskilldrop(_val);
        var _secondartskill = JSON.parse(JSON.stringify(secondarySkill));
        _secondartskill.splice(_secondartskill.indexOf(_value), 1);
        setsecondarySkill(_secondartskill);
        roleSelectValidation(_secondartskill, 2, 'Secondary skills', 'secondary')
        onChangeData(primarySkill, _secondartskill)
    }
    /**PRIMARY SKILL 1ST LEVEL DROPDOWN SELECT */
    const enableFirstLevel = () => {
        common.visibleAllParent('.skil-primary');
        const mark = document.querySelector(".primary");
        if (mark) {
        mark.classList.remove("move-label");
        }
        if (document.querySelectorAll('.skil-primary .demoFixedSubLevel')) {
        [...document.querySelectorAll('.skil-primary .demoFixedSubLevel')].forEach(div => {
            if (div.style.display !== 'none') {
            div.style.display = 'none'
            }
        });
        }
        const parent = primaryParentRef.current.getBoundingClientRect()
        document.querySelector('.skil-primary.demoFixed').style.left = parent.left + 'px';
        document.querySelector('.skil-primary.demoFixed').style.top = parent.top + 10 + 'px';
        document.querySelector('.skil-primary.demoFixed').style.width = parent.width + 'px';
        document.querySelector('.react-select__menu.skil-primary.demoFixed').style.display = 'block';
        document.querySelector('.skil-primary-multi-select-backdrop').style.display = 'block';
    }

    /**PRIMARY SKILL 2ND LEVEL DROPDOWN SELECT */
    const enableSecondLevel = (e, i) => {
        if(e.currentTarget.classList.length && e.currentTarget.classList.contains('active')) {
        e.currentTarget.classList.remove('active')
        document.querySelector('.skil-primary .demoFixedSubLevel.child_' + i).style.display = 'none';
        common.visibleAllParent('.skil-primary');
        return;
        }
        var elems = document.querySelector(".active");
        if (elems !== null) {
        elems.classList.remove("active");
        }
        e.currentTarget.className += " active";
        common.onlyActiveElementVisible('.skil-primary');
        const parent = primaryParentRef.current.getBoundingClientRect();
        const firstLevel = primaryParentRefdrop.current.getBoundingClientRect();
        document.querySelector('.skil-primary .demoFixedSubLevel.child_' + i).style.left = (parent.left + parent.width) + 'px';
        document.querySelector('.skil-primary .demoFixedSubLevel.child_' + i).style.top = firstLevel.top + 'px';
        document.querySelector('.skil-primary .demoFixedSubLevel.child_' + i).style.width = parent.width + 'px';
        skilldrop.forEach((_val, _i) => {
        if (_i === i) {
            document.querySelector('.react-select__menu.skil-primary .demoFixedSubLevel.child_' + i).style.display = 'block';
        } else {
            document.querySelector('.react-select__menu.skil-primary .demoFixedSubLevel.child_' + _i).style.display = 'none';
        }
        })

    }

    /**PRIMARY SKILL DISABLE ALL LEVEL DROPDOWN SELECT */
    const disableallLevel = () => {
        common.visibleAllParent('.skil-secondary');
        if(document.getElementsByName('primaryindustry')) {
        document.getElementsByName('primaryindustry').forEach(o => {
        if(o && o.value !== "") {
            o.value = ""
        }
        })
    }
        const mark = document.querySelector(".primary");
        if (mark) {
        mark.classList.add("move-label");
        }
        document.querySelector('.skil-primary.demoFixed').style.display = 'none';
        if (!primarySkill.length >= 3) {
        skilldrop.forEach((_val, _i) => {
            document.querySelector('.react-select__menu.skil-primary .demoFixedSubLevel.child_' + _i).style.display = 'none'
        })
        }
        document.querySelector('.skil-primary-multi-select-backdrop').style.display = 'none';
        const activeElement = document.querySelectorAll('.skil-primary.demoFixed .parent.active');
        if(activeElement && activeElement.length) {
        activeElement.forEach((act, _i) => {
            if(act.classList && act.classList.length) {act.classList.remove('active')}
        })
        }
    }

    /**SECONDARY SKILL 1ST LEVEL DROPDOWN SELECT */
    const enableFirstLevelSecondary = () => {
        common.visibleAllParent('.skil-secondary');
        const mark = document.querySelector(".secondary");
        if (mark) {
        mark.classList.remove("move-label");
        }
        if (document.querySelectorAll('.skil-secondary .demoFixedSubLevel')) {
        [...document.querySelectorAll('.skil-secondary .demoFixedSubLevel')].forEach(div => {
            if (div.style.display !== 'none') {
            div.style.display = 'none'
            }
        });
        }
        const parent = secondaryParentRef.current.getBoundingClientRect()
        document.querySelector('.skil-secondary.demoFixed').style.left = parent.left + 'px';
        document.querySelector('.skil-secondary.demoFixed').style.top = parent.top + 10 + 'px';
        document.querySelector('.skil-secondary.demoFixed').style.width = parent.width + 'px';
        document.querySelector('.react-select__menu.skil-secondary.demoFixed').style.display = 'block';
        document.querySelector('.skil-secondary-multi-select-backdrop').style.display = 'block';
    }

    /**SECONDARY SKILL 2ND LEVEL DROPDOWN SELECT */
    const enableSecondLevelSecondary = (e, i) => {
        if(e.currentTarget.classList.length && e.currentTarget.classList.contains('active')) {
        e.currentTarget.classList.remove('active')
        document.querySelector('.skil-secondary .demoFixedSubLevel.child_' + i).style.display = 'none';
        common.visibleAllParent('.skil-secondary');
        return;
        }
        var elems = document.querySelector(".active");
        if (elems !== null) {
        elems.classList.remove("active");
        }
        e.currentTarget.className += " active";
        common.onlyActiveElementVisible('.skil-secondary');
        const parent = secondaryParentRef.current.getBoundingClientRect();
        const firstLevel = secondaryParentRefdrop.current.getBoundingClientRect();
        document.querySelector('.skil-secondary .demoFixedSubLevel.child_' + i).style.left = (parent.left + parent.width) + 'px';
        document.querySelector('.skil-secondary .demoFixedSubLevel.child_' + i).style.top = firstLevel.top + 'px';
        document.querySelector('.skil-secondary .demoFixedSubLevel.child_' + i).style.width = parent.width + 'px';
        skilldrop.forEach((_val, _i) => {
        if (_i === i) {
            document.querySelector('.react-select__menu.skil-secondary .demoFixedSubLevel.child_' + i).style.display = 'block';
        } else {
            document.querySelector('.react-select__menu.skil-secondary .demoFixedSubLevel.child_' + _i).style.display = 'none';
        }
        })

    }

    /**SECONDARY SKILL DISABLE ALL LEVEL DROPDOWN SELECT */
    const disableallLevelSecondary = () => {
        if(document.getElementsByName('secondaryindustry')) {
        document.getElementsByName('secondaryindustry').forEach(o => {
        if(o && o.value !== "") {
            o.value = ""
        }
        })
    }
        const mark = document.querySelector(".secondary");
        if (mark) {
        mark.classList.add("move-label");
        }
        document.querySelector('.skil-secondary.demoFixed').style.display = 'none';
        document.querySelector('.skil-secondary-multi-select-backdrop').style.display = 'none';
        if (!secondarySkill.length >= 3) {
        skilldrop.forEach((_val, _i) => {
            document.querySelector('.react-select__menu.skil-secondary .demoFixedSubLevel.child_' + _i).style.display = 'none'
        })
        }
        const activeElement = document.querySelectorAll('.skil-secondary.demoFixed .parent.active');
        if(activeElement && activeElement.length) {
        activeElement.forEach((act, _i) => {
            if(act.classList && act.classList.length) {act.classList.remove('active')}
        })
        }
    }
    /**SAVE SKILLS VALUE ONCHANGE */
    function onChangeData(_primaryskill, _secondaryskill) {
        try {
        var _data = JSON.parse(JSON.stringify(clientStore.page3)); var skill = [];
        selectStore.skils.forEach(_val => {
            var _value = _val.sublabel ? _val.sublabel : _val.label;
            var _index = _primaryskill.indexOf(_value)
            var index = _secondaryskill.indexOf(_value)
            if (_index !== -1) {
            skill.push({ "skill_id": _val.skill_id, "is_primary": true })
            } else {
            if (index !== -1) {
                skill.push({ "skill_id": _val.skill_id, "is_primary": false })
            }
            }
        })
        _data["skills"] = skill;
        //   dispatch({ type: 'client_details/insertValue', payload: { "id": _data, "name": "page3" } });
        //   props.saveData({ "skills": skill })
        } catch (err) { console.log(err); }
    }

    function validateSkillsForm() {
        trigger();
        roleSelectValidation(primarySkill, 2, 'Primary skills', 'primary')
        roleSelectValidation(secondarySkill, 2, 'Secondary skills', 'secondary')
    }

    /** SUBMIT SKILLS VALUE */
    function submitHandler() {
        try {
            var skill = [];
            if(isValid && primarySkill.length >=2  && secondarySkill.length >=2 ){
                selectStore.skils.forEach(_val => {
                    var _value = _val.sublabel ? _val.sublabel : _val.label;
                    var _index = primarySkill.indexOf(_value)
                    var index = secondarySkill.indexOf(_value)
                    if (_index !== -1) {
                        skill.push({ "skill_id": _val.skill_id, "is_primary": true })
                    } else {
                        if (index !== -1) {
                        skill.push({ "skill_id": _val.skill_id, "is_primary": false })
                        }
                    }
                })
                dispatch({ type: 'client_details/insertValue', payload: { "id": { "skills": skill }, "name": "page3" } });
                // props.saveData({ "skills": skill);
                props.nextStep();
            } else {
                validateSkillsForm();
            }
        } catch (err) { console.log(err); }
    }

    /** GROUP BY LABEL */
    function groupBy(arr, property) {
        return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) { memo[x[property]] = []; }
        memo[x[property]].push(x);
        return memo;
        }, {});
    }

    /** SCREEN RESIZE */
    function resizeListener() {
        disableallLevel(); disableallLevelSecondary();
    }

    /** HANDLES ROLE SELECT VALUE VALIDATION */
    function roleSelectValidation(value, min, fieldname, type) {
        let message;
        switch (type) {
        case 'primary':
            if (!value.length) {
            setPriSkillIsvalid(false)
            message = common.formValidation(fieldname, 'required')
            setPriSkillErrorMsg(message)
            } else if (value.length < min) {
            setPriSkillIsvalid(false)
            message = common.formValidation(fieldname, 'minValue', min)
            setPriSkillErrorMsg(message)
            } else {
            setPriSkillIsvalid(true);
            setPriSkillErrorMsg()
            }
            break;
        case 'secondary':
            if (!value.length) {
            setSecSkillIsvalid(false)
            message = common.formValidation(fieldname, 'required')
            setSecSkillErrorMsg(message)
            } else if (value.length < min) {
            setSecSkillIsvalid(false)
            message = common.formValidation(fieldname, 'minValue', min)
            setSecSkillErrorMsg(message)
            } else {
            setSecSkillIsvalid(true);
            setSecSkillErrorMsg()
            }
            break;
        default:
            break;
        }
    }
    return(
        <div className="page-section">
            <Header isClient={true}></Header>
            <ClientOnboardingTemplate>
                <div className="role-textContainer">
                    <TextContainer head={head} subHead={subHead} activeTab="Skills" activepersentage="28.6%" text="3/7"/>
                </div>
                <form className="form-layer skills space-y-6 " autoComplete="off">
                    <div className="field-group custom-select" tabIndex="0">
                        {<label className="text-[16px] move-label primary top-0 text-[#a3a3a3] -z-1 duration-300 origin-0 select-label">Primary skills - choose 2</label>}
                        <input type="text" readOnly ref={primaryParentRef} name="primaryindustry" onFocus={enableFirstLevel} className="input-fld" />
                        <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>
                        <FormValidationIcon isArray={true} twolevelselect={true} minlen={2} getval={primarySkill}></FormValidationIcon>
                        <div className={primarySkill && primarySkill.length >= 2 ? 'skil-primary-multi-select-backdrop multi-select-backdrop max-limit' : 'skil-primary-multi-select-backdrop multi-select-backdrop '} onClick={disableallLevel}></div>
                    </div>
                    {primarySkill && primarySkill.length >= 2
                        ?
                        <div className="multi-select react-select__menu skil-primary demoFixed">
                        <div className="react-select__menu-list react-select__menu-list--is-multi react-select__menu-list--is-multi-max-limit">
                            Max limit achieved
                        </div>
                        </div>
                        :
                        <div ref={primaryParentRefdrop} className="multi-select react-select__menu skil-primary demoFixed">
                        <div className="react-select__menu-list react-select__menu-list--is-multi">
                            {skilldrop.map((com_id, i) =>
                            <div className="react-select__option" key={i}  >
                                <li onClick={(e) => { enableSecondLevel(e, i) }} className={com_id.checked ? "parent active" : "parent"}><span className="txt">
                                {com_id.label}
                                <span className="expand">
                                    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.23654 4.79183L1.06988 8.9585L0.0976562 7.98627L3.2921 4.79183L0.0976559 1.59738L1.06988 0.625163L5.23654 4.79183Z" fill="#0E0E0E"/></svg>
                                </span></span>
                                </li>
                                <ul className={"child demoFixedSubLevel child_" + i}>
                                {com_id["sublist"].map((_v, _i) => 
                                    <li className={(_v.isSecondary) ? "opacity-[30%] option-selected pointer-events-none cursor-not-allowed" : "cursor-pointer"} key={_i} onClick={(e) => { handlePrimarySkillSelectOnchangeCheckbox(com_id, _v, i, _i, e) }}><span className="txt" key={_i}> {_v.sublabel ? _v.sublabel : _v.label}
                                    {/* <label className="ch-box"><input type="checkbox" className={`${_v.isSecondary ? 'pointer-events-none cursor-not-allowed' : ''}`} disabled={_v.isSecondary} checked={_v.isChecked} onChange={() => { handlePrimarySkillSelectOnchangeCheckbox(com_id, _v, i, _i) }} /></label> */}
                                    <label className="control control--checkbox">
                                    <input type="checkbox" className={`${_v.isSecondary ? 'pointer-events-none cursor-not-allowed' : ''}`} disabled={_v.isSecondary} checked={_v.isChecked} onChange={() => { handlePrimarySkillSelectOnchangeCheckbox(com_id, _v, i, _i) }}/>
                                    <div className="control__indicator"></div>
                                    </label>
                                    </span>
                                </li>)}
                                </ul>
                            </div>
                            )}
                        </div>
                        </div>
                    }
                    <SelectFormFieldError isValid={priSkillIsvalid} message={priSkillErrorMessage}></SelectFormFieldError>
                    <div className={`${primarySkill && primarySkill.length ? '!mt-0 !mb-[24px] custom-multi-select' : ''}  `}>
                        {JSON.parse(JSON.stringify(primarySkill)).map((val, key) => (
                        <div className="react-select__control inline-block " key={key}><div className="react-select__value-container">
                            <div className="react-select__multi-value">
                            <div className="react-select__multi-value__label css-wsp0cs-MultiValueGeneric">{val}</div>
                            <div role="button" onClick={() => handlePrimarySkillSelectOnClose(val)} className="react-select__multi-value__remove">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                            </svg>
                            </div></div>
                        </div></div>
                        ))}
                    </div>
                    <div className="field-group custom-select" tabIndex="1" >
                        {<label className="text-[16px] secondary move-label top-0 text-[#a3a3a3] -z-1 duration-300 origin-0 select-label">Secondary skills - choose 2</label>}
                        <input type="text" ref={secondaryParentRef} readOnly name="secondaryindustry" onFocus={enableFirstLevelSecondary} className="input-fld" />
                        <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>
                        <FormValidationIcon isArray={true} twolevelselect={true} minlen={2} getval={secondarySkill}></FormValidationIcon>
                        <div className={secondarySkill.length >= 2 ? 'skil-secondary-multi-select-backdrop multi-select-backdrop max-limit' : 'skil-secondary-multi-select-backdrop multi-select-backdrop'} onClick={disableallLevelSecondary}></div>
                    </div>
                    {secondarySkill && secondarySkill.length >= 2
                        ?
                        <div className="multi-select react-select__menu skil-secondary demoFixed">
                        <div className="react-select__menu-list react-select__menu-list--is-multi react-select__menu-list--is-multi-max-limit">
                            Max limit achieved
                        </div>
                        </div>
                        :
                        <div ref={secondaryParentRefdrop} className="multi-select react-select__menu skil-secondary  demoFixed">
                        <div className="react-select__menu-list react-select__menu-list--is-multi">
                            {skilldrop.map((com_id, i) =>
                            <div className="react-select__option" key={i}  >
                                <li onClick={(e) => { enableSecondLevelSecondary(e, i) }} className={com_id.checked ? "parent active" : "parent"}><span className="txt">
                                {com_id.label}
                                <span className="expand">
                                    <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.23654 4.79183L1.06988 8.9585L0.0976562 7.98627L3.2921 4.79183L0.0976559 1.59738L1.06988 0.625163L5.23654 4.79183Z" fill="#0E0E0E"/></svg>
                                </span></span>
                                </li>
                                <ul className={"child demoFixedSubLevel child_" + i}>
                                {com_id["sublist"].map((_v, _i) =>
                                    <li className={(_v.isPrimary) ? "opacity-[30%] option-selected pointer-events-none cursor-not-allowed" : "cursor-pointer"} key={_i}  onClick={(e) => { handleSecondarySkillSelectOnchangeCheckbox(com_id, _v, i, _i, e) }}>
                                    <span className="txt" key={_i}> {_v.sublabel ? _v.sublabel : _v.label}
                                        {/* <label className="ch-box"><input type="checkbox" className={`${_v.isPrimary ? 'pointer-events-none cursor-not-allowed' : ''}`} disabled={_v.isPrimary} checked={_v.isChecked} onChange={() => { handleSecondarySkillSelectOnchangeCheckbox(com_id, _v, i, _i) }} />
                                        </label> */}
                                        <label className="control control--checkbox">
                                        <input type="checkbox" className={`${_v.isPrimary ? 'pointer-events-none cursor-not-allowed' : ''}`} disabled={_v.isPrimary} checked={_v.isChecked} onChange={() => { handleSecondarySkillSelectOnchangeCheckbox(com_id, _v, i, _i) }}/>
                                        <div className="control__indicator"></div>
                                        </label>
                                    </span>
                                    </li>
                                )}
                                </ul>
                            </div>
                            )}
                        </div>
                        </div>
                    }
                    <SelectFormFieldError isValid={secSkillIsvalid} message={secSkillErrorMessage}></SelectFormFieldError>
                    <div className={`${secondarySkill && secondarySkill.length ? '!mt-0 !mb-[24px] custom-multi-select' : ''}  `}>
                        {JSON.parse(JSON.stringify(secondarySkill)).map((val, index) => (
                        <div className="react-select__control inline-block " key={index}>
                            <div className="react-select__value-container">
                            <div className="react-select__multi-value">
                            <div className="react-select__multi-value__label css-wsp0cs-MultiValueGeneric">{val}</div>
                            <div role="button" onClick={() => handleSecondarySkillSelectOnClose(val)}  className="react-select__multi-value__remove">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                            </svg>
                            </div></div>
                        </div></div>
                        ))}
                    </div>
                </form>
            </ClientOnboardingTemplate>
            <FooterWithBackNext validateForm={() => { validateSkillsForm() }} disabled={(!isValid || secondarySkill.length < 2 || primarySkill.length < 2)}  submitHandler={submitHandler} prevStep={props.prevStep} persentage="14.28%" text="2/7"></FooterWithBackNext>
        </div>
    )
}