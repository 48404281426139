import { createSlice } from "@reduxjs/toolkit";

var initialState = {
    page1: {
        starting_point: ""
    },
    page2: {
        skills: []
    },
    page3: {
        diversity: [],
        industry: [],
        company: [],
        markets: [],
        targetGeographic: [],
        targetGlobal: false,
        budget: 0,
        tone_of_voice: [],
        additional_requirements: []
    },
    page4: {
        addreq_need_account_lead: false,
        addreq_need_famous: false,
        addreq_need_media_buying: false,
        addreq_need_media_planning: false,
        addreq_need_ongoing_services: false,
        addreq_need_production: false,
        addreq_need_strategic_planner: false
    },
    page5: {
    },
    skils_group: {},
    project_id: "",
    providers: [],
    teams_platform: '',
    results: []
}

export const projectsetup = createSlice({
    name: "project_setup",
    initialState: initialState,
    reducers: {
        insertValue: (state, action) => {
            state[action.payload.name] = action.payload.id;
            return state
        },
        reset: () => {
            return initialState
        }
    }
})
export const { insertValue } = projectsetup.actions

export default projectsetup.reducer