import React from 'react';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()
class Rendertoast extends React.Component {
    constructor(props) {
        super(props);
        this.showToast = this.showToast.bind(this);
    }
    showToast(obj) {
        if (obj !== null) {
            if (obj.type === "e") {
                toast.error(<div className='toaster-message'>{obj.msg}</div>, { transition: Slide, position: "top-center", hideProgressBar: true, style: { "textAlign": "justify", "margin": "0 auto", "backgroundColor": "#d52a2a", "Color": "#FFFFFF" }, closeButton: false })
            } else if (obj.type === "w") {
                toast.warning(<div className='toaster-message'>{obj.msg}</div>, { transition: Slide, position: "top-center", hideProgressBar: true, style: { "textAlign": "justify", "margin": "0 auto", "backgroundColor": "#47BB33", "Color": "#FFFFFF" }, closeButton: true })
            } else if (obj.type === "s") {
                toast.success(<div className='toaster-message'>{obj.msg}</div>, { transition: Slide, position: "top-center", hideProgressBar: true, style: { "textAlign": "justify", "margin": "0 auto", "backgroundColor": "#47BB33", "Color": "#FFFFFF" }, closeButton: false })
            } else {
                toast(<div className='toaster-message'>{obj.msg}</div>, { transition: Slide, position: "top-center", hideProgressBar: true, style: { "textAlign": "justify", "margin": "0 auto", "backgroundColor": "#47BB33", "Color": "#FFFFFF" }, closeButton: false })
            }
        }
    }
}

const rendertoast = new Rendertoast();
export default rendertoast;